<template>
  <div>
    <div class="page-section border-bottom-2">
      <div :class="containerClass">
        <div>
          <h3>{{ `Welcome ${this.getLoggedInUser.first_name}!` }}</h3>
        </div>
        <org-setup-guide :pending="true" @pendingGuides="pendingGuides" />
        <div v-if="pending > 0" class="text-center">
          <router-link :to="{ name: 'org-setup-guide' }"> View {{ pending }} more </router-link>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.org_schlrshp_count }}</h4>
                </b-skeleton-wrapper>
                <div>Sponsorships</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.unlocked_students_count }}</h4>
                </b-skeleton-wrapper>
                <div>Unlocked Students</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.org_job_count }}</h4>
                </b-skeleton-wrapper>
                <div>My Jobs</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.emp_job_count }}</h4>
                </b-skeleton-wrapper>
                <div>My Employer's Jobs</div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-lg-6">
            <div class="card text-center mb-lg-0">
              <div class="card-body">
                <b-skeleton-wrapper :loading="isLoading">
                  <template #loading>
                    <h4 class="h2 my-2"><b-skeleton width="100%" height="1.9rem"></b-skeleton></h4>
                  </template>

                  <h4 class="h2 mb-0">{{ dashboard.emp_count }}</h4>
                </b-skeleton-wrapper>
                <div>Employers</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import OrgSetupGuide from '../SetupGuide/OrgSetupGuide.vue';
export default {
  components: { OrgSetupGuide },
  extends: Page,

  data() {
    return {
      title: 'Dashboard',

      dashboard: {
        emp_count: 0,
        emp_schlrshp_count: 0,
        org_schlrshp_count: 0,
        unlocked_students_count: 0,
        org_job_count: 0,
        emp_job_count: 0,
      },

      isLoading: false,
      pending: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Dashboard', active: true },
      ];
    },
  },

  methods: {
    ...mapActions('organization', ['getDashboard']),
    pendingGuides(pending) {
      this.pending = pending;
    },
    async fetchDashboard() {
      this.isLoading = true;
      const response = await this.getDashboard();
      this.dashboard = response.data;
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchDashboard();
  },
};
</script>
