<template>
  <div :class="containerClass">
    <page-header title="Make a hire" :container-class="null" class="mb-32pt" />

    <page-separator title="" />
    <div class="mx-3 mb-4">
      <span v-if="!fromOrganization">
        Complete the form below to hire candidates from your partner schools. A reference fee

        <span v-if="referenceFee !== null"
          >of <b>${{ $n(referenceFee) }} per hire </b></span
        >
        will be charged to
        <i v-if="isPayMethodLoading">Loading...</i>
        <b v-else-if="paymentMethod">card ending with {{ get(paymentMethod, 'last_four') }}</b>
        <a href="#" @click.prevent="addPaymentMethodClick" v-else class="text-primary">Add payment</a>.
      </span>
    </div>

    <div class="card">
      <div class="card-body">
        <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
          <b-form-group v-if="!fromOrganization" label="School" label-for="schools" label-class="form-label">
            <v-select
              id="school"
              class="form-control v-select-custom"
              label="name"
              v-model="school"
              :reduce="sc => sc.id"
              @input="schoolChanged"
              placeholder="Select Schools"
              :options="allSchools"
              :loading="areSchoolsLoading"
              :class="!$v.school.required && $v.school.$dirty ? 'form-control is-invalid' : ''"
              :disabled="isLoading || areSchoolsLoading"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>

              <template slot="option" slot-scope="option">
                <div class="d-flex align-items-center">
                  <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                    <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                    <i class="fas fa-university fa-lg text-primary" v-else></i>
                  </fmv-avatar>
                  <span class="ml-1">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.name }}
              </template>
            </v-select>
            <b-form-invalid-feedback :state="!$v.school.required && $v.school.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Candidates" label-for="candidates" label-class="form-label">
            <v-select
              id="candidates"
              :filter-by="filterCandidates"
              class="form-control v-select-custom"
              label="user"
              v-model="candidates"
              multiple
              :reduce="candidate => candidate.id"
              placeholder="Select Candidates"
              :options="allCandidates"
              :loading="areCandidatesLoading"
              :class="!$v.candidates.required && $v.candidates.$dirty ? 'form-control is-invalid' : ''"
              :disabled="isLoading || areCandidatesLoading"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-flex align-items-center">
                  <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                    <b-img
                      :src="option.user.avatar_url"
                      class="img-fluid"
                      width="20"
                      alt="Logo"
                      v-if="option.user.avatar_url"
                    />
                    <md-icon class="text-primary" v-else>person</md-icon>
                  </fmv-avatar>
                  <span class="ml-1">{{ option.user.first_name }} {{ option.user.last_name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                {{ option.user.first_name }} {{ option.user.last_name }}
              </template>
            </v-select>
            <b-form-invalid-feedback :state="!$v.candidates.required && $v.candidates.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Job" label-for="job" label-class="form-label">
            <v-select
              id="job"
              class="form-control v-select-custom"
              label="title"
              v-model="job"
              :reduce="sc => sc.id"
              placeholder="Select Job"
              :options="allJobs"
              :class="!$v.job.required && $v.job.$dirty ? 'form-control is-invalid' : ''"
              :loading="areJobsLoading"
              :disabled="isLoading || areJobsLoading"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
            <b-form-invalid-feedback :state="!$v.job.required && $v.job.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Pay Rate" label-for="pay_rate" label-class="form-label">
            <v-select
              id="pay_rate"
              class="form-control v-select-custom"
              label="text"
              v-model="pay_rate"
              :reduce="sc => sc.value"
              placeholder="Select Pay Rate"
              :options="allPayRates"
              :class="!$v.pay_rate.required && $v.pay_rate.$dirty ? 'form-control is-invalid' : ''"
              :disabled="isFormLoading"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
            <b-form-invalid-feedback :state="!$v.pay_rate.required && $v.pay_rate.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="Salary" label-for="salary" label-class="form-label">
            <currency-input
              v-model="salary"
              class="form-control"
              :class="!$v.salary.required && $v.salary.$dirty ? 'is-invalid' : ''"
              placeholder="Enter salary"
              :allow-negative="false"
              :disabled="isFormLoading"
            />
            <b-form-invalid-feedback :state="!$v.salary.required && $v.salary.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>
          <b-form-group label="Hiring Date" label-for="hiring_date" label-class="form-label">
            <date-picker
              v-model="hiring_date"
              format="DD MMMM, YYYY"
              valueType="date"
              style="width: 100%"
              id="hiring_date"
              lang="en"
              placeholder="Enter Hiring Date"
              :state="!$v.hiring_date.required && $v.hiring_date.$dirty ? false : null"
              :input-class="
                `mx-input ${!$v.hiring_date.required && $v.hiring_date.$dirty ? 'form-control is-invalid' : ''}`
              "
              :disabled="isFormLoading"
            ></date-picker>
            <b-form-invalid-feedback :state="!$v.hiring_date.required && $v.hiring_date.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
            <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
            <span v-else>Make a hire</span>
          </b-btn>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { required,requiredIf } from 'vuelidate/lib/validators';
import Page from '../../../components/Page.vue';
import DatePicker from 'vue2-datepicker';
//import moment from 'moment';
import { PAYMENT_STATUSES } from '../../../common/constants';
import { formatToAPIDate } from '../../../common/utils';
// import UserAvatar from '../../../components/User/UserAvatar.vue';
import 'vue-swatches/dist/vue-swatches.css';
export default {
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    DatePicker,
  },
  extends: Page,

  data() {
    return {
      fromOrganization: false,
      title: 'Make a Hire',
      school: null,
      allSchools: [],
      referenceFee: null,
      candidates: [],
      allCandidates: [],
      allJobs: [],
      job: null,
      salary: null,
      pay_rate: null,
      hiring_date: null,
      paymentMethod: null,
      allPayRates: [
        { value: 'hourly', text: 'Hourly' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'annually', text: 'Annually' },
      ],

      isLoading: false,
      isLogoUploading: false,
      areSchoolsLoading: false,
      areCandidatesLoading: false,
      areJobsLoading: false,
      isPayMethodLoading: false,

      uploadFile: { logo: null, profileBgImage: null },
      uploadPercent: { logo: 0, profileBgImage: 0 },
      uploadCancelTokenSource: { logo: null, profileBgImage: null },
      ordering: '-is_active,-id',
    };
  },
  validations() {
    return {
      school: { required: requiredIf(() => !this.fromOrganization) },
      candidates: { required },
      job: { required },
      salary: { required },
      pay_rate: { required },
      hiring_date: { required },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Hires', to: { name: 'hires' } },
        {
          text: 'Make a Hire',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.isLogoUploading ||
        this.areSchoolsLoading ||
        this.areCandidatesLoading ||
        this.areJobsLoading ||
        this.isPayMethodLoading
      );
    },
  },

  methods: {
    ...mapActions('school', ['getPartneredSchools']),
    ...mapActions('student', ['getToBeHiredStudents']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('payments', ['verifyPaymentInfo', 'getDefaultPaymentMethod']),
    ...mapActions('hire', ['createHire', 'hirePaymentSuccess', 'hirePaymentCancel']),
    ...mapActions('job', ['getAllJobs']),
    get,

    filterCandidates(option, label, search) {
      let temp = search.toLowerCase();
      // if (typeof label === 'number') {
      //   label = label.toString();
      // }

      return (
        // label.toLowerCase().indexOf(temp) > -1 ||
        get(option, 'user.first_name', '')
          .toLowerCase()
          .indexOf(temp) > -1 ||
        get(option, 'user.last_name', '')
          .toLowerCase()
          .indexOf(temp) > -1 ||
        `${get(option, 'user.first_name', '')} ${get(option, 'user.last_name', '')}`.toLowerCase().indexOf(temp) > -1
      );
    },
    OrganizationChanged(value) {
      this.fetchStudents(value);
      this.fetchJobs(value);
    },
    schoolChanged(value) {
      if (value) {
        this.fetchStudents(value);
        this.fetchJobs(value);
        const schoolSelected = this.allSchools.find(sc => sc.id === value);

        this.referenceFee = schoolSelected.partner_reference_fee;
      } else {
        this.candidates = [];
        this.referenceFee = null;
        this.job = null;
        this.allJobs = [];
        this.allCandidates = [];
      }
    },
    async fetchStudents(id, params = {}) {
      this.areCandidatesLoading = true;
      const response = await this.getToBeHiredStudents({
        school: id,
        ...params,
      });
      if (!this.candidates.length) {
        this.candidates = [];
      }
      this.allCandidates = response;
      this.areCandidatesLoading = false;
    },
    async addPaymentMethodClick() {
      const isPayConfirmed = await this.openNoPaymentConfirmation();
      if (isPayConfirmed) {
        this.$router.push({ name: 'manage-payment-method' });
      }
    },
    async fetchPaymentMethod() {
      this.isPayMethodLoading = true;
      try {
        this.paymentMethod = get((await this.getDefaultPaymentMethod()).data, 'data');
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isPayMethodLoading = false;
    },
    async fetchPartneredSchools(params = {}) {
      this.areSchoolsLoading = true;
      const response = await this.getPartneredSchools({
        ...params,
      });
      this.allSchools = response.data;
      this.areSchoolsLoading = false;
    },
    async fetchJobs(id, params = {}) {
      this.areJobsLoading = true;
      const response = await this.getAllJobs({
        limit: 15,
        offset: 0,
        ...(this.ordering && { ordering: this.ordering }),
        incl_school: id,
        ...params,
      });
      this.job = null;
      this.allJobs = response.data.results;

      this.areJobsLoading = false;
    },
    async openNoPaymentConfirmation(okTitle = 'Add Payment') {
      return this.$bvModal.msgBoxConfirm(
        'You must have a payment method on file before hiring candidates. You will be charged a reference fee on hiring upon hiring a candidate.',
        {
          title: 'Add Payment',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          cancelVariant: 'danger',
          okTitle,
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        },
      );
    },
    async openHireConfirmation() {
      const h = this.$createElement;

      return this.$bvModal.msgBoxConfirm(
        h('span', [
          'A reference fee of ',
          h('b', `$${this.referenceFee * this.candidates.length}`),
          ' will be charged to your account for hiring the selected candidates. Please confirm to proceed.',
        ]),

        // `A reference fee of $${
        //   this.referenceFee * this.candidates.length
        // } will be charged to your account for hiring the selected candidates. Please confirm to proceed.`,
        {
          title: 'Confirm Hiring',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          cancelVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        },
      );
    },
    async onSubmit() {
      this.isLoading = true;
      let isStripeRedirecting = false;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const isHireConfirmed = await this.openHireConfirmation();

        if (isHireConfirmed) {
          try {
            const resp = await this.createHire({
              school: this.school,
              candidate: this.candidates,
              job: this.job,
              pay_rate: this.pay_rate,
              salary: this.salary,
              hiring_date: formatToAPIDate(this.hiring_date),
              ...(this.fromOrganization && { with_in_org: true }),
            });
            resp.session_url;
            if (resp.status_code === 200) {
              this.makeToast({ variant: 'success', msg: 'Candidates Hired!' });
              setTimeout(() => this.$router.push({ name: 'hires' }), 250);
            } else if (resp.status_code === 303) {
              isStripeRedirecting = true;
              window.location.href = resp.data.session_url;
            }
          } catch (err) {
            if (get(err, 'response.data.data.status_msg') === PAYMENT_STATUSES.PAYMENT_FAILURE) {
              this.makeToast({
                variant: 'danger',
                msg: 'Cannot hire candidate due to payment issue. Please update your payment method and try again.',
              });
              setTimeout(() => this.$router.push({ name: 'hires' }), 1000);
            }
            this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          }
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = isStripeRedirecting;
    },
  },

  async mounted() {   
    this.isPayMethodLoading = true;
    this.fromOrganization = this.$route.query.fromOrganization; // Convert to boolean
    if (this.fromOrganization) {
      this.OrganizationChanged();
      if(this.$route.query.student){
        this.candidates = [parseInt(this.$route.query.student)];
      }
    }

    if (this.$route.query.paymentSuccess) {
      this.isLoading = true;
      const draftId = this.$route.query.draftId;
      const stripeSessionId = this.$route.query.stripeSessionId;
      try {
        await this.hirePaymentSuccess({ draft_id: draftId, session_id: stripeSessionId });
        this.makeToast({ variant: 'success', msg: 'Candidates Hired!' });
      } catch (err) {
        if (get(err, 'response.data.data.status_msg') === PAYMENT_STATUSES.PAYMENT_FAILURE) {
          this.makeToast({
            variant: 'danger',
            msg: 'Cannot hire candidate due to payment issue. Please update your payment method and try again.',
          });
        } else {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      setTimeout(() => this.$router.push({ name: 'hires' }), 1000);
    } else if (this.$route.query.paymentFailed) {
      this.isLoading = true;
      const draftId = this.$route.query.draftId;
      try {
        await this.hirePaymentCancel({ draft_id: draftId });
        this.makeToast({ variant: 'danger', msg: 'Cannot hire candidate. Please try again!' });
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      setTimeout(() => this.$router.push({ name: 'hires' }), 1000);
    } else {
      await this.fetchPaymentMethod();
      await this.fetchPartneredSchools();
      if (this.$route.query.school && this.$route.query.student) {
        this.school = parseInt(this.$route.query.school);
        this.candidates = [parseInt(this.$route.query.student)];

        this.schoolChanged(this.school);
      }
    }
  },
};
</script>
