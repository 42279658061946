<template>
  <div style="padding-top: 16px" id="page-container">
    <div class="page-section candidates_container">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <h4 class="mb-0 mr-5">
            {{
              getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
                ? 'Students'
                : $route.name === 'employer-unlocked-candidates-list'
                ? 'Unlocked Candidates'
                : 'Candidates'
            }}
            <i
              class="material-icons icon-16pt clickable-item"
              v-b-popover.hover.top="
                getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION && $route.name === 'associated-students-list'
                  ? `List of all active candidates who are associated with your organization.`
                  : getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
                  ? `List of all candidates who belong to the school your employers are partnered with.`
                  : getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
                    $route.name === 'employer-unlocked-candidates-list'
                  ? 'List of all candidates unlocked by you.'
                  : getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && $route.name === 'associated-students-list'
                  ? 'List of all active candidates who are associated with your organization.'
                  : 'List of all candidates who belong to your partnered schools, unlock each candidate for his details.'
              "
              >info</i
            >
          </h4>
          <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
            <small class="text-muted text-headings text-uppercase d-sm-block" v-if="!isMobSmallScreen">Sort by</small>
            <b-dropdown variant="link text-black-70" right no-caret>
              <template #button-content>
                <span>{{ $t(`empStdSortOptions.${ordering}`) }}<i class="fas fa-sort ml-1"></i></span>
              </template>

              <b-dropdown-item
                :active="ordering === 'user__first_name,user__last_name'"
                @click="onSortChange({ sortBy: 'user__first_name,user__last_name' })"
                >Name</b-dropdown-item
              >
              <b-dropdown-item :active="ordering === 'city'" @click="onSortChange({ sortBy: 'city' })"
                >Location</b-dropdown-item
              >
              <b-dropdown-item :active="ordering === '-updated_at'" @click="onSortChange({ sortBy: '-updated_at' })"
                >Last Updated</b-dropdown-item
              >
            </b-dropdown>
            <b-btn
              v-if="enableSidebarToggle !== false"
              v-fmv-toggle.candidates-filters
              size="sm"
              variant="white"
              class="mr-2"
            >
              <md-icon left>tune</md-icon>
              <span>Filters</span>
            </b-btn>
            <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
              <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />
              <b-btn variant="flush" type="submit">
                <md-icon v-text="'search'" />
              </b-btn>
            </form>
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${totalStudents ? `${pageFromCount}-${pageToCount}` : 0} of ${totalStudents} ${
                  getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'Students' : 'Candidates'
                }`
              : ''
          "
        />

        <div class="d-flex justify-content-center" v-if="!totalStudents && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>{{ noStudentsFoundTitle }}</h4>

              <p class="text-muted">
                {{ noStudentsFoundMsg }}
              </p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-sm-12 card-group-row__col" v-for="student in students" :key="student.id">
              <div
                class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card"
                @click.prevent="openStudentProfile(student.id)"
              >
                <div class="card-header d-flex align-items-center">
                  <a href="#" @click.prevent class="card-title flex mr-12pt">
                    {{ student.user.first_name }} {{ student.user.last_name }}
                    <i
                      class="material-icons icon-16pt ml-2 text-primary"
                      v-if="showUnlockIcon(student)"
                      v-b-popover.hover.top="'This candidate is unlocked.'"
                      >lock_open</i
                    >
                  </a>
                  <div class="d-flex justify-content-center mb-2">
                    <b-badge
                      :class="
                        'border border-' +
                          (student.is_profile_complete && student.is_profile_visible && student.user.is_active
                            ? `success badge-success`
                            : `danger badge-danger`)
                      "
                      variant="outlined"
                      class="mt-1"
                      style="font-size: 0.81rem"
                      v-b-popover.hover.top
                      :title="
                        student.is_profile_complete && student.is_profile_visible && student.user.is_active
                          ? ``
                          : !student.is_profile_complete && student.is_profile_visible && student.user.is_active
                          ? `This student hasn't on-boarded yet`
                          : (student.is_profile_complete && !student.is_profile_visible && student.user.is_active) ||
                            (!student.is_profile_complete && !student.is_profile_visible && student.user.is_active)
                          ? `The profile of this student is hidden`
                          : `The profile of this student is disabled`
                      "
                      v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL"
                    >
                      {{
                        student.is_profile_complete && student.is_profile_visible && student.user.is_active
                          ? 'Active'
                          : !student.is_profile_complete && student.is_profile_visible && student.user.is_active
                          ? 'Incomplete'
                          : (student.is_profile_complete && !student.is_profile_visible && student.user.is_active) ||
                            (!student.is_profile_complete && !student.is_profile_visible && student.user.is_active)
                          ? 'Hidden'
                          : 'Inactve'
                      }}
                    </b-badge>
                  </div>
                  <span
                    :id="`school-popover-${student.id}`"
                    v-if="getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL && get(student, 'school.name')"
                  >
                    <i class="material-icons icon-16pt mr-1 text-dark">account_balance</i>
                    <a
                      style="text-decoration: underline; color: #01b3e6"
                      href="#"
                      @click.prevent
                      class=""
                      data-placement="bottom"
                      >{{ get(student, 'school.name') }}</a
                    >
                  </span>
                  <school-popover
                    v-if="getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL"
                    :school="get(student, 'school')"
                    :targetId="student.id"
                    :active-std-count="get(schoolStdCounts, `${get(student, 'school.id')}.students_count`, 0)"
                    :std-count-loading="stdCountLoading"
                  ></school-popover>
                </div>
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="flex">
                      <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                        <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                          <!-- Old avatar image style (use if needed) -->
                          <!-- <a
                            href="#"
                            @click.prevent
                            class="
                              avatar avatar-xl
                              overlay
                              js-overlay
                              overlay--primary
                              rounded-circle
                              p-0
                              o-hidden
                              d-flex
                              justify-content-center
                              align-items-center
                            "
                            style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                          >
                            <img
                              class="avatar-img m-0"
                              :src="student.user.avatar_url || DefaultAvatar"
                              width="160"
                              height="auto"
                            />
                          </a> -->
                          <user-avatar size="5rem" :user="student.user" hide-text />
                        </div>
                        <div class="flex">
                          <div class="mb-0" style="text-transform: none">
                            <div v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL">
                              <i class="material-icons icon-16pt">email</i>
                              <a :href="`mailto:${student.user.email}`" class="mb-2">
                                <b style="color: black">
                                  {{ student.user.email }}
                                </b>
                              </a>
                            </div>
                            <div>
                              <i class="material-icons icon-16pt">location_on</i> Lives near
                              <strong style="font-weight: bold">{{ student.city }}, {{ student.state }}</strong> •
                              Willing to travel
                              <strong style="font-weight: bold">{{ student.work_travel }} miles</strong>
                            </div>
                            <div>
                              <i class="material-icons icon-16pt">timelapse</i> Seeking
                              <strong style="font-weight: bold">{{ student.desired_work_type }}</strong>
                            </div>
                            <div>
                              <span>
                                <i class="material-icons icon-16pt">favorite</i>
                                <strong style="font-weight: bold"> {{ student.work_experience }} year(s)</strong>
                                experience
                              </span>
                              <span v-if="student.curr_employer">
                                • Last employed at
                                <strong style="font-weight: bold">{{ student.curr_employer || 'None' }}</strong></span
                              >
                            </div>
                            <div>
                              <span>
                                <i class="material-icons icon-16pt">calendar_today</i> Profile last updated
                                <strong style="font-weight: bold">{{ formatTimeAgo(student.updated_at) }}</strong>
                              </span>
                            </div>
                          </div>
                          <p class="flex text-50 mb-0" style="font-size: 14px; font-style: italic" v-if="student.quote">
                            “{{ student.quote }}”
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-button">
                    <ul>
                      <li v-for="cert in student.certifications" :key="cert.key">
                        <a href="#" @click.prevent
                          ><span class="material-icons icon-16pt text-100">check</span>{{ cert.name }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalStudents > students.length"
          v-model="currentPage"
          :total-rows="totalStudents"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="students-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';
import { concat, debounce, get, keyBy, map, trim, truncate, uniq, some } from 'lodash';
import Page from '@/components/Page.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import SchoolPopover from './SchoolPopover/SchoolPopover.vue';
import CandidateFilters from './CandidateFilters/CandidateFilters.vue';
import CandFiltersSidebar from './CandidateFilters/CandFiltersSidebar.vue';
import UserAvatar from '../../components/User/UserAvatar.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLES, USER_ROLE_TYPES, STD_UNLOCK_STATUSES } from '../../common/constants';
import { formatTimeAgo } from '../../common/utils';

export default {
  components: { Pagination, SchoolPopover, UserAvatar, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      USER_ROLES,
      USER_ROLE_TYPES,
      STD_UNLOCK_STATUSES,

      title: null,

      noStudentsFoundTitle: null,
      noStudentsFoundMsg: null,

      isLoading: true,
      perPage: 14,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      pageFromCount: 0,
      pageToCount: 0,

      searchTerm: '',
      ordering: 'user__first_name,user__last_name',

      filters: {
        zipcode: null,
        user__gender: null,
        work_experience: null,
        lang_preference: null,
        facility_type: null,
        desired_work_type: null,
        desired_work_day: null,
        desired_work_time: null,
        certifications: null,
        records: null,
        piis: null,
        enrolled_program__title: null,
        student_status: null,
      },

      certRecordsTypes: [
        { key: 'cna_license_no', name: 'CNA', downloadable: false },
        { key: 'cma_license_no', name: 'CMA', downloadable: false },
      ],

      stdCountLoading: true,
      unlockedStatusesLoading: false,
      schoolStdCounts: {},
      unlockedStatusesById: {},

      windowWidth: window.innerWidth,
      firstLoad: true,
    };
  },

  computed: {
    ...mapGetters('student', ['getCandidateEmpFilters']),
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Candidates', active: true },
      ];
    },

    subLayout() {
      return this.windowWidth > 992;
    },
    subLayoutDrawer() {
      return this.windowWidth > 992 ? CandFiltersSidebar : CandidateFilters;
    },
    subLayoutDrawerId() {
      return 'candidates-filters';
    },

    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  watch: {
    '$route.name': {
      immediate: true,
      handler(value) {
        if (value) {
          this.filters.zipCode = null;
          this.filters.zipCodeRange = 30;

          this.filters.certifications = [];
          this.filters.records = [];
          this.filters.piis = [];
          this.filters.availability = [];
          this.filters.facilityType = null;
          this.filters.workDay = [];
          this.filters.workTime = [];
          this.filters.langPreferences = null;

          this.filters.experience = null;
          this.filters.gender = null;
          this.filters.enrolled_program__title = null;
          this.filters.student_status = null;
        }
      },
    },
    getCandidateEmpFilters: {
      deep: true,
      handler(filterValues) {
        this.filters.zipcode = filterValues.zipCode;
        this.filters.user__gender = filterValues.gender;
        this.filters.work_experience = filterValues.experience;
        this.filters.lang_preference = filterValues.langPreferences;
        this.filters.facility_type = filterValues.facilityType;
        this.filters.desired_work_type = filterValues.availability.length ? filterValues.availability.join(',') : null;
        this.filters.desired_work_day = filterValues.workDay.length ? filterValues.workDay.join(',') : null;
        this.filters.desired_work_time = filterValues.workTime.length ? filterValues.workTime.join(',') : null;
        this.filters.certifications = filterValues.certifications.length ? filterValues.certifications.join(',') : null;

        const piis = concat(filterValues.piis, filterValues.records);
        this.filters.piis = get(piis, 'length') ? piis.join(',') : null;

        this.filters.records = null;
        this.filters.enrolled_program__title = filterValues.enrolled_program__title;
        this.filters.student_status = filterValues.student_status;

        if (some(this.filters)) {
          this.$router.replace({
            query: {
              ...(this.filters.zipcode && { zipcode: this.filters.zipcode }),
              ...(this.filters.user__gender && { user__gender: this.filters.user__gender }),
              ...(this.filters.work_experience && { work_experience: this.filters.work_experience }),
              ...(this.filters.lang_preference && { lang_preference: this.filters.lang_preference }),
              ...(this.filters.facility_type && { facility_type: this.filters.facility_type }),
              ...(this.filters.desired_work_type && { desired_work_type: this.filters.desired_work_type }),
              ...(this.filters.desired_work_day && { desired_work_day: this.filters.desired_work_day }),
              ...(this.filters.desired_work_time && { desired_work_time: this.filters.desired_work_time }),
              ...(this.filters.certifications && { certifications: this.filters.certifications }),

              ...(this.filters.piis && { piis: this.filters.piis }),

              ...(this.filters.records && { records: this.filters.records }),
              ...(this.filters.enrolled_program__title && {
                enrolled_program__title: this.filters.enrolled_program__title,
              }),
              ...(this.filters.student_status && { student_status: this.filters.student_status }),
            },
          });
        }
        this.debouncedSearchStudents(this);
      },
    },
  },

  methods: {
    ...mapActions('student', [
      'getAllStudents',
      'getStudentsUnlockedStatuses',
      'getUnlockedStudents',
      'getOrgUnlockedStudents',
      'getOrgStudentsUnlockedStatuses',
      'getAssociatedStudents',
    ]),
    ...mapActions('school', ['getSchoolStudentCounts']),
    get,
    trim,
    truncate,
    formatTimeAgo,
    showUnlockIcon(student) {
      return (
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
          get(this.unlockedStatusesById, `${student.id}.status`, STD_UNLOCK_STATUSES.LOCKED) ===
            STD_UNLOCK_STATUSES.UNLOCKED) ||
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION &&
          get(this.unlockedStatusesById, `${student.id}.status`, STD_UNLOCK_STATUSES.LOCKED) ===
            STD_UNLOCK_STATUSES.UNLOCKED)
      );
    },
    async fetchSchoolStdCounts() {
      this.stdCountLoading = true;
      const schools = uniq(this.students.filter(std => get(std, 'school.id')));
      const schoolIds = uniq(schools.map(std => get(std, 'school.id')));
      const queryData = {
        only_recently_active: false,
        schools: schoolIds,
      };
      const resp = await this.getSchoolStudentCounts({
        ...queryData,
      });
      this.schoolStdCounts = keyBy(resp, 'school_id');

      this.stdCountLoading = false;
    },

    async fetchStudents(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('page-container').scrollIntoView();
      let response;

      const commonParams = {
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...((this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION) && { is_profile_complete: true }),
        ...this.filters,
        ...params,
      };

      // if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION) {
      //   response = await this.getOrgUnlockedStudents({
      //     ...commonParams,
      //     is_profile_visible: true,
      //     user__is_active: true,
      //   });
      // } else {

      if (this.$route.name === 'employer-unlocked-candidates-list') {
        response = await this.getUnlockedStudents({
          ...commonParams,
          is_profile_visible: true,
          user__is_active: true,
        });
      } else if (this.$route.name === 'org-unlock-students-list') {
        response = await this.getOrgUnlockedStudents({
          ...commonParams,
          is_profile_visible: true,
          user__is_active: true,
        });
      } else if (this.$route.name === 'associated-students-list') {
        response = await this.getAssociatedStudents({
          ...commonParams,
          is_profile_visible: true,
          user__is_active: true,
        });
      } else {
        response = await this.getAllStudents({
          ...commonParams,
        });
      }
      response.data.results.forEach(student => {
        student.desired_work_type = student.desired_work_type
          .map(type => this.$t(`studentWorkTypeOpts.${type}`))
          .join(', ');
        student.desired_work_time = student.desired_work_time
          .map(time => this.$t(`studentWorkTimeOpts.${time}`))
          .join(', ');

        const certs = [];
        this.certRecordsTypes.forEach(certType => {
          if (student[certType.key]) {
            certs.push(certType);
          }
        });
        student['certifications'] = certs;
      });

      this.students = response.data.results;
      this.currentPage = pageNum;
      this.totalStudents = response.data.count;
      if (this.getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL) {
        this.fetchSchoolStdCounts();
      }
      this.fetchUnlockedStatuses();
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStudents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce(vm => {
      vm.fetchStudents();
    }, 500),

    openStudentProfile(id) {
      if (
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION ||
          this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) &&
        this.$route.name === 'associated-students-list'
      ) {
        this.$router.push({
          name: 'associated-candidate-profile',
          params: { id },
        });
      } else if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION) {
        this.$router.push({
          name: 'org-student-details',
          params: { id },
        });
      } else {
        this.$router.push({
          name:
            this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL
              ? 'schl-student-profile'
              : this.$route.name === 'employer-unlocked-candidates-list'
              ? 'employer-unlocked-candidate-profile'
              : this.$route.name === 'org-unlock-students-list'
              ? 'org-unlocked-candidate-profile'
              : 'employer-candidate-profile',
          params: {
            id,
            ...(this.$route.name === 'employer-unlocked-candidates-list' && { to: 'unlock' }),
            ...(this.$route.name === 'org-unlock-students-list' && { to: 'org-unlock' }),
          },
        });
      }
    },

    async fetchUnlockedStatuses() {
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
        this.unlockedStatusesLoading = true;

        const statuses = (await this.getStudentsUnlockedStatuses({ students: map(this.students, 'id') })).data;
        this.unlockedStatusesById = keyBy(statuses, 'id');

        this.unlockedStatusesLoading = false;
      } else if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION) {
        this.unlockedStatusesLoading = true;

        const statuses = (await this.getOrgStudentsUnlockedStatuses({ students: map(this.students, 'id') })).data;
        this.unlockedStatusesById = keyBy(statuses, 'id');

        this.unlockedStatusesLoading = false;
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    setTitle() {
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
        this.title = 'Students';
        this.noStudentsFoundTitle = 'No Students Found';
        this.noStudentsFoundMsg = `Couldn't find any students. We are constantly onboarding new students so please check again later.`;
      } else {
        if (this.$route.params.to === 'unlock') {
          this.title = 'Unlocked Candidates';
        } else {
          this.title = 'Candidates';
        }
        this.noStudentsFoundTitle = 'No Candidates Found';
        this.noStudentsFoundMsg = `Couldn't find any candidates. We are constantly onboarding new students so please check again later.`;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.firstLoad) {
        vm.setTitle();
        vm.fetchStudents();
      }
    });
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    this.setTitle();
    this.firstLoad = false;
    if (!some(this.$route.query)) {
      this.fetchStudents();
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
