<template>
  <div style="margin-top: -16px">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="page-section bg-dark border-bottom-white">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar :title="true" rounded size="xxl" no-link :class="!isMobSmallScreen ? 'mr-3' : ''">
              <b-skeleton-img height="113px" no-aspect></b-skeleton-img>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0"><b-skeleton width="85%"></b-skeleton></h2>
              <p class="lead text-white-50">
                <b-skeleton width="60%"></b-skeleton>
              </p>
            </div>
            <b-skeleton type="button" v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER"></b-skeleton>
            <b-skeleton type="button" class="ml-2"></b-skeleton>
          </div>
        </div>
      </template>

      <div
        class="bg-dark page-section employer-header-bg border-bottom-white mb-10pt img-fluid"
        :style="`background-image: url('${school.profile_bg_img_url}');`"
      >
        <div class="overlay__content page-section employer-profile-cover-ol">
          <div
            class="d-flex flex-column flex-md-row align-items-center text-center text-md-left"
            :class="containerClass"
          >
            <fmv-avatar
              :title="true"
              rounded
              size="xxl"
              no-link
              :class="!isMobSmallScreen ? 'mr-3' : ''"
              title-class="bg-white"
            >
              <b-img
                :src="school.logo_url || DefaultAvatar"
                class="img-fluid"
                width="104"
                alt="Logo"
                v-if="school.logo_url"
              />
              <md-icon v-else>account_balance</md-icon>
            </fmv-avatar>

            <div class="flex mb-32pt mb-md-0">
              <h2 class="text-white mb-0" v-text="school.name" />

              <b-skeleton-wrapper :loading="stdCountLoading">
                <template #loading>
                  <p class="lead text-white-50">
                    <b-skeleton width="60%"></b-skeleton>
                  </p>
                </template>

                <p class="lead text-white-50 d-flex align-items-center">
                  <span class="d-flex align-items-center">
                    <i class="material-icons icon-16pt mr-4pt">school</i>
                    {{ schoolStudents }} {{ schoolStudents > 1 ? 'Active Students' : 'Active Student' }}
                  </span>
                </p>
              </b-skeleton-wrapper>
            </div>

            <partnership-request-button
              v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER"
              :partnership="partnership"
              :entity="school"
              :school-id="schoolId"
              :custom-class="'btn-white'"
              :container-class="isMobSmallScreen ? 'mb-2' : ''"
              :type="USER_ROLE_TYPES.SCHOOL"
              :is-partnership-loading="isPartnershipLoading"
              :open-details-modal="openDetailsModal"
              :open-request-partnership-detail-modal="openRequestPartnershipDetailModal"
              @hideReqDetailsModal="hideRequestPartnershipDetailModal"
              :is-partnership-responding="isPartnershipResponding"
              @isResponding="updatePartnerRespLoader"
              @fetchPartnership="fetchPartnership"
            />
          </div>
        </div>
      </div>

      <div :class="containerClass">
        <div style="display: center">
          <b-tabs
            nav-class="nav-tabs-card bg-white px-3"
            content-class=" "
            class="mt-0"
            id="homePageTab"
            @input="onTabsChanged"
            style="scroll-margin-top: 66px; font-size: 16.5px"
          >
            <b-tab title="Overview" class="tab-secondary">
              <template #title>
                <i class="fas fa-info align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Overview</strong>
              </template>
              <div class="page-section">
                <div class="row card-group-row mb-lg-12pt home-card">
                  <div
                    class="col-sm-12 card-group-row__col"
                    v-if="
                      get(partnership, 'status') === PARTNERSHIP_STATUS.SENT &&
                      get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN
                    "
                  >
                    <div class="card card-block card-stretch card-height col-md-12">
                      <div class="card-body text-center">Partnership request has been sent.</div>
                    </div>
                  </div>
                </div>
                <div class="page-section student-card mt-0 pt-0 mb-32pt">
                  <div class="card border-left-0">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">About</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex">
                              <div
                                class="d-flex align-items-center mt-2"
                                v-if="
                                  get(school, 'address') &&
                                  get(school, 'city') &&
                                  get(school, 'state') &&
                                  get(school, 'zipcode') &&
                                  get(school, 'country')
                                "
                              >
                                <md-icon class="font-size-16pt mr-2">location_on</md-icon>
                                {{ get(school, 'address') }}, {{ get(school, 'city') }}, {{ get(school, 'state') }}
                                {{ get(school, 'zipcode') }},
                                {{ get(school, 'country') }}
                              </div>

                              <div class="d-flex align-items-center mt-2" v-if="school.phone">
                                <md-icon class="font-size-16pt mr-2">phone</md-icon>
                                <a :href="`tel:${school.phone}`" class="text-black">{{ school.phone }}</a>
                              </div>

                              <div class="d-flex align-items-center mt-2" v-if="school.website_url">
                                <md-icon class="font-size-16pt mr-2">language</md-icon>
                                <a :href="getClickableLink(school.website_url)" target="_blank" class="text-black">{{
                                  getClickableLink(school.website_url)
                                }}</a>
                              </div>

                              <div
                                class="d-flex align-items-center mt-2"
                                v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER"
                              >
                                <md-icon class="font-size-16pt mr-2">school</md-icon>
                                <a class="text-black"
                                  >{{ schoolStudents }}
                                  {{ schoolStudents > 1 ? 'Active Students' : 'Active Student' }}</a
                                >
                              </div>

                              <div
                                v-if="get(school, 'offered_programs.length') >= 0"
                                class="d-flex align-items-center mt-2"
                              >
                                <md-icon class="font-size-16pt mr-2">local_library</md-icon>
                                <a class="text-black" style="cursor: pointer">
                                  {{ school.offered_programs.length > 1 ? 'Offered Programs' : 'Offered Program' }}
                                  (<span
                                    class="text-primary"
                                    @click.prevent="ShowprogramModal(school.offered_programs)"
                                    >{{ school.offered_programs.length }}</span
                                  >)
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-left-0" v-if="school.bio">
                    <div class="card-body">
                      <div class="d-flex mb-1">
                        <div class="flex">
                          <div class="d-flex align-items-center mb-1">
                            <h5 class="card-title fw600">Bio</h5>
                          </div>

                          <div class="d-flex">
                            <div class="flex program-description">
                              <div v-safe-html="get(school, 'bio', '')" v-if="school.bio" />

                              <i v-else>{{ school.name }} has not added any bio.</i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>

            <!-- School's Active Students -->
            <b-tab
              title="Students"
              class="tab-secondary"
              v-if="get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.EMPLOYER"
            >
              <template #title>
                <i class="fas fa-user-graduate align-self-center" :class="{ 'mx-4': isTabSmallScreen }"></i>
                <strong class="ml-2" v-if="!isTabSmallScreen">Students</strong>
              </template>
              <div
                :class="containerClass"
                class="page-section mb-0"
                v-if="
                  getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL &&
                  get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN
                "
              >
                <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
                  <div
                    class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0"
                    v-if="
                      !isEmpty(partnership) && get(partnership, 'status') !== PARTNERSHIP_STATUS.SENT && totalStudents
                    "
                  >
                    <small class="text-muted text-headings text-uppercase d-sm-block">Sort by</small>
                    <b-dropdown variant="link text-black-70" right no-caret>
                      <template #button-content>
                        <span>{{ $t(`empStdSortOptions.${studentOrdering}`) }}<i class="fas fa-sort ml-1"></i></span>
                      </template>

                      <b-dropdown-item
                        :active="studentOrdering === 'user__first_name,user__last_name'"
                        @click="onStudentsSortChange({ sortBy: 'user__first_name,user__last_name' })"
                        >Name</b-dropdown-item
                      >
                      <b-dropdown-item
                        :active="studentOrdering === 'city'"
                        @click="onStudentsSortChange({ sortBy: 'city' })"
                        >Location</b-dropdown-item
                      >
                      <b-dropdown-item
                        :active="studentOrdering === '-updated_at'"
                        @click="onStudentsSortChange({ sortBy: '-updated_at' })"
                        >Last Updated</b-dropdown-item
                      >
                    </b-dropdown>

                    <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                      <b-form-input
                        class="w-lg-auto"
                        placeholder="Search..."
                        v-model="studentSearchTerm"
                        @input="onStudentSearch"
                      />
                      <b-btn variant="flush" type="submit">
                        <md-icon v-text="'search'" />
                      </b-btn>
                    </form>
                  </div>
                </div>

                <!-- <page-separator title="" /> -->

                <div class="d-flex justify-content-center" v-if="!totalStudents && !areStudentsLoading">
                  <div class="card card-block card-stretch card-height col-md-12">
                    <div class="card-body text-center">
                      <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
                      <h4>No Students Found</h4>
                      <p class="text-muted">
                        Couldn't find any students. We are constantly onboarding new students so please check again
                        after a while.
                      </p>
                    </div>
                  </div>
                </div>

                <b-skeleton-wrapper :loading="areStudentsLoading">
                  <template #loading>
                    <div class="row card-group-row mb-lg-12pt home-card">
                      <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
                        <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                          <div class="card-header d-flex align-items-center">
                            <a href="#" @click.prevent class="card-title flex">
                              <b-skeleton width="100%"></b-skeleton
                            ></a>
                          </div>
                          <div class="card-body d-flex flex-column">
                            <div class="d-flex align-items-center">
                              <div class="flex">
                                <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                                  <div
                                    class="mr-12pt"
                                    :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }"
                                  >
                                    <a
                                      href="#"
                                      @click.prevent
                                      class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                                      style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                                    >
                                      <b-skeleton-img
                                        class="avatar-img rounded"
                                        width="160"
                                        height="100%"
                                      ></b-skeleton-img>
                                    </a>
                                  </div>
                                  <div class="flex">
                                    <div class="mb-0" style="text-transform: none">
                                      <b-skeleton width="100%"></b-skeleton>
                                      <b-skeleton width="55%"></b-skeleton>
                                      <b-skeleton width="70%"></b-skeleton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <div class="row card-group-row mb-lg-12pt home-card">
                    <div class="col-sm-12 card-group-row__col" v-for="student in students" :key="student.id">
                      <div
                        class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card"
                        @click.prevent="openStudentProfile(student.id)"
                      >
                        <div class="card-header d-flex align-items-center">
                          <a href="#" @click.prevent class="card-title flex mr-12pt">
                            {{ student.user.first_name }} {{ student.user.last_name }}
                            <i
                              class="material-icons icon-16pt ml-2 text-primary"
                              v-if="
                                getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
                                get(unlockedStatusesById, `${student.id}.status`, STD_UNLOCK_STATUSES.LOCKED) ===
                                  STD_UNLOCK_STATUSES.UNLOCKED
                              "
                              v-b-popover.hover.top="'This candidate is unlocked.'"
                              >lock_open</i
                            >
                          </a>
                        </div>
                        <div class="card-body d-flex flex-column">
                          <div class="d-flex align-items-center">
                            <div class="flex">
                              <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                                <div
                                  class="mr-12pt"
                                  :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }"
                                >
                                  <a
                                    v-if="get(partnership, 'status') !== PARTNERSHIP_STATUS.ACCEPTED"
                                    href="#"
                                    @click.prevent
                                    class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-0 o-hidden d-flex justify-content-center align-items-center"
                                    style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                                  >
                                    <img
                                      class="avatar-img m-0 rounded-circle"
                                      :src="
                                        student.user.gender === GENDERS.MALE ? MaleBlurredAvatar : FemaleBlurredAvatar
                                      "
                                      width="160"
                                      height="auto"
                                    />
                                  </a>
                                  <user-avatar v-else size="5rem" :user="student.user" hide-text />
                                </div>
                                <div class="flex">
                                  <div class="mb-0" style="text-transform: none">
                                    <div>
                                      <i class="material-icons icon-16pt">location_on</i> Lives near
                                      <strong style="font-weight: bold">{{ student.city }}, {{ student.state }}</strong>
                                      • Willing to travel
                                      <strong style="font-weight: bold">{{ student.work_travel }} miles</strong>
                                    </div>
                                    <div>
                                      <i class="material-icons icon-16pt">timelapse</i> Seeking
                                      <strong style="font-weight: bold">{{ student.desired_work_type }}</strong>
                                    </div>
                                    <div>
                                      <span>
                                        <i class="material-icons icon-16pt">favorite</i>
                                        <strong style="font-weight: bold">
                                          {{ student.work_experience }} year(s)</strong
                                        >
                                        experience
                                      </span>
                                      <span v-if="student.curr_employer">
                                        • Last employed at
                                        <strong style="font-weight: bold">{{
                                          student.curr_employer || 'None'
                                        }}</strong></span
                                      >
                                    </div>
                                    <div>
                                      <span>
                                        <i class="material-icons icon-16pt">calendar_today</i> Profile last updated
                                        <strong style="font-weight: bold">{{
                                          formatTimeAgo(student.updated_at)
                                        }}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <p
                                    class="flex text-50 mb-0"
                                    style="font-size: 14px; font-style: italic"
                                    v-if="student.quote"
                                  >
                                    “{{ student.quote }}”
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card-button">
                            <ul>
                              <li v-for="cert in student.certifications" :key="cert.key">
                                <a href="#" @click.prevent
                                  ><span class="material-icons icon-16pt text-100">check</span>{{ cert.name }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-skeleton-wrapper>

                <pagination
                  v-if="totalStudents > students.length"
                  v-model="currentPage"
                  :total-rows="totalStudents"
                  :per-page="perPage"
                  @change="onStudentsPageChange"
                  aria-controls="students-table"
                />

                <div class="row card-group-row mb-lg-12pt home-card">
                  <div
                    class="col-sm-12 card-group-row__col"
                    v-if="isEmpty(partnership) && get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN"
                  >
                    <div class="card card-block card-stretch card-height col-md-12">
                      <div class="card-body text-center">
                        <p class="text-muted">
                          Active students are visible for partner schools. Request partnership with {{ school.name }} to
                          view the school's active students and get other partnership benefits.
                        </p>

                        <partnership-request-button
                          :partnership="partnership"
                          :entity="school"
                          :school-id="schoolId"
                          :custom-class="'btn-primary'"
                          :container-class="'d-flex justify-content-center'"
                          :type="USER_ROLE_TYPES.SCHOOL"
                          :is-partnership-loading="isPartnershipLoading"
                          :is-partnership-responding="isPartnershipResponding"
                          @isResponding="updatePartnerRespLoader"
                          @fetchPartnership="fetchPartnership"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div
        class="d-flex justify-content-center mt-4"
        v-if="
          get(partnership, 'status') === PARTNERSHIP_STATUS.RECEIVED &&
          get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN
        "
      >
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">
            <p class="text-muted">
              {{
                `${school.name} has sent you a partnership request with the following message. Respond now to become partners.`
              }}
            </p>
            <div class="d-flex align-items-start mb-2">
              <h5 class="card-title fw600">Partnership Fees</h5>
            </div>

            <div class="d-flex align-items-start">
              <span
                ><b>Initiation Fee:</b> ${{ $n(partnership.initial_computed_fee) }} (${{
                  $n(partnership.partner_initial_fee)
                }}
                / Recently Active Student
                <i class="material-icons icon-16pt ml-1 clickable-item" v-b-popover.hover.top="ACTIVE_STD_INFO_DESC"
                  >info</i
                >)</span
              >
            </div>

            <div class="d-flex align-items-start mb-2">
              <span><b>Recurring Fee:</b> ${{ $n(partnership.partner_recurring_fee) }} / Month</span>
            </div>
            <div class="d-flex align-items-start mb-2">
              <h5 class="card-title fw600">Requested Programs</h5>
            </div>
            <div class="d-flex align-items-start" v-for="(program, index) in partnership.programs" :key="index">
              <span><md-icon class="mr-2 text-50 icon-16pt">check_circle</md-icon>{{ program.title }}</span>
            </div>

            <div class="d-flex align-items-start mt-4">
              <h5 class="card-title fw600">Message</h5>
            </div>
            <div class="card card-block card-stretch card-height mt-2">
              <div class="card-body text-left">
                <div v-html="partnership.message"></div>
              </div>
            </div>
            <partnership-request-button
              :partnership="partnership"
              :entity="school"
              :custom-class="'btn-primary'"
              :school-id="schoolId"
              :container-class="'d-flex justify-content-center'"
              :type="USER_ROLE_TYPES.SCHOOL"
              :is-partnership-loading="isPartnershipLoading"
              :is-partnership-responding="isPartnershipResponding"
              @isResponding="updatePartnerRespLoader"
              @fetchPartnership="fetchPartnership"
            />
          </div>
        </div>
      </div>
    </b-skeleton-wrapper>

    <show-programs-modal :showModal="showModal" :programs="programs" @close="hideStateModal" />
  </div>
</template>

<script>
// import PageHeader from '@/components/Ui/PageHeader.vue';

import Page from '@/components/Page.vue';
import { mapActions, mapGetters } from 'vuex';
import { formatFullDate, formatTimeAgo } from '../../../common/utils';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get, isEmpty, keyBy, map } from 'lodash';

import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import MaleBlurredAvatar from '@/assets/images/people/blurred/male_blurred_std_avatar.jpg';
import FemaleBlurredAvatar from '@/assets/images/people/blurred/female_blurred_std_avatar.jpg';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import {
  STUDENT_CERT_RECORD_TYPES,
  USER_ROLE_TYPES,
  PARTNERSHIP_STATUS,
  USER_ROLES,
  ACTIVE_STD_INFO_DESC,
  GENDERS,
  STD_UNLOCK_STATUSES,
} from '../../../common/constants';
import PartnershipRequestButton from '../../Partnership/PartnershipRequestButton.vue';
import UserAvatar from '../../../components/User/UserAvatar.vue';

import ShowProgramsModal from './ShowProgramsModal.vue';

export default {
  components: { MdIcon, FmvAvatar, Pagination, PartnershipRequestButton, UserAvatar, ShowProgramsModal },
  extends: Page,
  name: 'SchoolProfile',

  data() {
    return {
      DefaultAvatar,
      MaleBlurredAvatar,
      FemaleBlurredAvatar,
      USER_ROLE_TYPES,
      USER_ROLES,
      STD_UNLOCK_STATUSES,

      title: 'School Profile',

      isLoading: true,
      areStudentsLoading: true,
      stdFirstLoading: true,

      stdCountLoading: false,
      school: {},
      schoolId: null,
      schoolStudents: 0,

      totalStudents: 0,
      students: [],
      perPage: 10,
      currentPage: 1,

      studentSearchTerm: '',
      studentOrdering: 'user__first_name,user__last_name',

      windowWidth: window.innerWidth,

      showRequestModal: false,
      partnership: {},
      isPartnershipLoading: false,
      PARTNERSHIP_STATUS,
      openDetailsModal: false,

      ACTIVE_STD_INFO_DESC,
      GENDERS,

      isPartnershipResponding: false,
      openRequestPartnershipDetailModal: false,

      unlockedStatusesLoading: false,
      unlockedStatusesById: {},
      firstLoad: true,
      showModal: false,
      programs: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'School Profile', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },

  methods: {
    ...mapActions('school', ['getSchool', 'getSchoolStudentCounts']),
    ...mapActions('student', ['getAllStudents', 'getBlurredStudents', 'getStudentsUnlockedStatuses']),
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('partnership', ['getPartnership', 'getStatus', 'acceptPartnershipPaymentSuccess']),
    get,
    isEmpty,
    formatFullDate,
    formatTimeAgo,
    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },
    hideRequestPartnershipDetailModal() {
      this.openRequestPartnershipDetailModal = false;
    },
    updatePartnerRespLoader(val) {
      this.isPartnershipResponding = val;
    },

    async fetchSchoolStdCounts() {
      this.stdCountLoading = true;
      const schoolId = this.$route.params.id || this.getLoggedInUser.linked_entity.id;
      const queryData = {
        only_recently_active: false,
        schools: [schoolId],
      };
      const resp = await this.getSchoolStudentCounts({
        ...queryData,
      });
      this.schoolStudents = get(resp[0], 'students_count', 0);

      this.stdCountLoading = false;
    },
    async fetchPartnership() {
      this.isPartnershipLoading = true;

      const response = await this.getStatus({ target_request_id: this.school.id, target_request_type: 'school' });
      this.partnership = response.data;

      if (this.$route.params.detailModal) {
        this.openDetailsModal = true;
      }
      if (this.getLoggedInUser.role_type !== USER_ROLE_TYPES.SCHOOL) {
        this.fetchStudents(1, {}, true);
      }

      this.isPartnershipLoading = false;
    },
    ShowprogramModal(programs) {
      this.showModal = true;
      this.programs = programs;
    },

    hideStateModal() {
      this.showModal = false;
    },
    async fetchSchool() {
      this.isLoading = true;

      try {
        const empId = this.$route.params.id || this.getLoggedInUser.linked_entity.id;
        const resp = (await this.getSchool(empId)).data;

        this.school = resp;

        this.schoolId = this.school.id;
      } catch (err) {
        this.$router.push({
          name:
            this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'schl-schools-list' : 'student-schools-list',
        });
      }

      this.isLoading = false;
    },

    async downloadGivenFile(url) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });

      await this.downloadFile({ fileUrl: url, removeTimestamp: true });

      this.makeToast({
        variant: 'success',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
        toaster: 'b-toaster-bottom-left',
      });
    },

    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },

    async fetchStudents(pageNum = 1, params = {}, firstLoad = false) {
      this.areStudentsLoading = true;
      if (firstLoad) this.stdFirstLoading = true;
      let response;
      let length;
      if (get(this.partnership, 'status') === PARTNERSHIP_STATUS.ACCEPTED) {
        const resData = await this.getAllStudents({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          school: this.$route.params.id || this.getLoggedInUser.linked_entity.id,
          ...(this.studentOrdering && { ordering: this.studentOrdering }),
          ...(this.studentSearchTerm && { search: this.studentSearchTerm }),
          user__is_active: true,
          ...params,
        });

        response = resData.data.results;
        length = resData.data.count;
      } else {
        response = await this.getBlurredStudents({
          school: this.$route.params.id || this.getLoggedInUser.linked_entity.id,
        });
        length = response.length;
      }
      response.forEach((student) => {
        student.desired_work_type = student.desired_work_type
          .map((type) => this.$t(`studentWorkTypeOpts.${type}`))
          .join(', ');

        const certs = [];
        STUDENT_CERT_RECORD_TYPES.forEach((certType) => {
          if (student[certType.key]) {
            certs.push(certType);
          }
        });
        student['certifications'] = certs;
      });

      this.students = response;
      this.currentPage = pageNum;
      this.totalStudents = length;
      if (firstLoad) {
        this.stdFirstLoading = false;
      }
      this.fetchUnlockedStatuses();
      this.areStudentsLoading = false;
    },

    onStudentsPageChange(pageNum) {
      this.fetchStudents(pageNum);
    },

    onStudentsSortChange(context) {
      this.studentOrdering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onStudentSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce((vm) => {
      vm.fetchStudents();
    }, 500),

    async fetchUnlockedStatuses() {
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
        this.unlockedStatusesLoading = true;

        const statuses = (await this.getStudentsUnlockedStatuses({ students: map(this.students, 'id') })).data;
        this.unlockedStatusesById = keyBy(statuses, 'id');

        this.unlockedStatusesLoading = false;
      }
    },

    openStudentProfile(id) {
      if (!isEmpty(this.partnership) && get(this.partnership, 'status') === PARTNERSHIP_STATUS.ACCEPTED) {
        if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
          this.$router.push({ name: 'schl-student-profile', params: { id } });
        } else {
          this.$router.push({ name: 'employer-candidate-profile', params: { id } });
        }
      } else if (isEmpty(this.partnership)) {
        this.openRequestPartnershipDetailModal = true;
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    if (this.$route.query.acceptPartnership) {
      const pid = this.$route.query.pid;
      const stripeSessionId = this.$route.query.stripeSessionId;
      try {
        await this.acceptPartnershipPaymentSuccess({ part_id: pid, session_id: stripeSessionId });
        this.makeToast({ variant: 'success', msg: 'Request Accepted!' });
        this.$router.push({ name: 'emp-school-profile', params: { id: this.$route.params.id } });
      } catch (err) {
        this.makeToast({
          variant: 'danger',
          msg: 'Cannot accept partnership due to payment issue. Please verify your payment method',
        });
      }
    } else if (this.$route.query.acceptPaymentFailed) {
      this.makeToast({ variant: 'danger', msg: 'Cannot accept partnership due to payment issue. Please try again!' });
      this.$router.push({ name: 'emp-school-profile', params: { id: this.$route.params.id } });
    }
    await this.fetchSchool();

    if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
      this.fetchPartnership();
    }
    this.fetchSchoolStdCounts();
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
