<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      info-text="Manage candidates hiring of your partnered schools."
    />
    <div class="page-section">
      <div :class="containerClass">
        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Hires'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <b-btn
                  v-if="getLoggedInUser && getLoggedInUser.linked_entity && getLoggedInUser.linked_entity.organization"
                  @click="openHireModal"
                  variant="primary"
                >
                  Make a Hire
                </b-btn>
                <b-btn v-else :to="{ name: 'make-a-hire' }" exact variant="primary">
                  Make a Hire
                </b-btn>
              </div>
            </div>
          </div>
          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="8" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Schools Table -->
            <b-table
              :fields="tableColumns"
              :items="hires"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              hover
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
            >
              <template #cell(logo_url)="data">
                <user-avatar slot="aside" size="md" :user="data.item" :fileUrl="data.item.candidate.user.avatar_url">
                </user-avatar>
              </template>
              <template #cell(candidate)="data">
                <a>
                  <router-link
                    :to="{
                      name: 'employer-candidate-profile',
                      params: { id: data.item.candidate.id, backTo: 'hires' },
                    }"
                    style="text-decoration: underline"
                    class="text-primary"
                    >{{ data.item.candidate.user.first_name }} {{ data.item.candidate.user.last_name }}</router-link
                  >
                </a>
              </template>
              <template #cell(status)="data">
                <b-badge
                  :class="
                    'border border-' + (data.item.status === 'active' ? `success text-success` : `danger text-danger`)
                  "
                  variant="none"
                  v-b-popover.hover.left
                  :title="data.item.status === 'active' ? `Contract is active` : 'Contract has been ended'"
                  >{{ data.item.status === 'active' ? 'Active' : 'Contract Ended' }}</b-badge
                >
              </template>

              <template #cell(school)="data">
                {{ data.item.school }}
              </template>

              <template #cell(job)="data">
                {{ data.item.job.title }}
              </template>

              <template #cell(salary)="data"> ${{ $n(data.item.salary) }} </template>
              <template #cell(pay_rate)="data"> {{ $t(`jobPayRateOpts.${data.item.pay_rate}`) }} </template>

              <template #head(actions)="">
                <span></span>
              </template>
              <template #cell(actions)="data">
                <a
                  v-if="data.item.status === 'active'"
                  href="#"
                  @click.prevent="openEndModal(data.item.id)"
                  v-b-popover.hover.left
                  title="End Contract"
                >
                  <md-icon class="text-danger">block </md-icon>
                </a>
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalHires"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="hires-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
    <hire-modal :show-modal="showHireModal" @close="hideHireModal" />
    <end-contract-modal :show-modal="showEndContractModal" :id="selectedRowId" @hide="hideEndModal" />
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { get, debounce } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '../../../components/Ui/Pagination.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE } from '../../../common/constants';
import { formatDateTime, formatFullDate } from '../../../common/utils';
import UserAvatar from '../../../components/User/UserAvatar.vue';
import EndContractModal from './EndContractModal.vue';
import HireModal from './HireModal.vue';

export default {
  components: { MdIcon, PageHeader, Pagination, UserAvatar, EndContractModal, HireModal },
  extends: Page,

  name: 'HireList',

  data() {
    return {
      title: 'Manage Hires',

      isLoading: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      totalHires: 0,
      hires: [],
      showEndContractModal: false,
      searchTerm: '',
      isDownloading: false,
      selectedRowId: null,
      showHireModal: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Hires', active: true },
      ];
    },

    tableColumns() {
      return [
        { key: 'logo_url', label: '' },
        { key: 'candidate', label: 'Candidate' },
        { key: 'status', label: 'Status' },
        { key: 'candidate.school.name', label: 'School' },
        { key: 'job', label: 'job' },
        { key: 'salary', label: 'salary' },
        { key: 'pay_rate', label: 'Pay rate' },
        { key: 'hiring_date', label: 'Hiring Date', formatter: value => (value ? formatFullDate(value) : value) },
        {
          key: 'contract_end_date',
          label: 'Contract End Date',
          formatter: value => (value ? formatFullDate(value) : value),
        },

        { key: 'actions', tdClass: 'text-right ', thStyle: { minWidth: '100px' } },
      ];
    },
  },

  methods: {
    ...mapActions('hire', ['deleteHire', 'getAllHires']),
    formatDateTime,
    formatFullDate,
    openEndModal(id) {
      this.selectedRowId = id;
      this.showEndContractModal = true;
    },
    hideEndModal() {
      this.showEndContractModal = false;
      this.selectedRowId = null;
      this.fetchHires();
    },
    openHireModal() {
      this.showHireModal = false;
      if (get(this.getLoggedInUser.linked_entity, 'organization')) {
        this.showHireModal = true;
      }
    },
    hideHireModal() {
      this.showHireModal = false;
    },
    async fetchHires(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllHires({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.hires = response.data.results;
      this.currentPage = pageNum;
      this.totalHires = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchHires(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchHires();
    },

    onSearch() {
      this.debouncedSearchHires(this);
    },

    debouncedSearchHires: debounce(vm => {
      vm.fetchHires();
    }, 500),
  },

  async mounted() {
    this.fetchHires();
  },
};
</script>
