<template>
  <b-modal ref="endContractModal" hide-footer title="End Contract" centered lazy scrollable @hide="hideModal" size="md">
    <div class="d-flex align-items-start">
      <span> Please ensure that you want to end the Contract. </span>
    </div>
    <b-form-group label="End Contract Reason" label-for="endContractReason" label-class="form-label" class="mt-3">
      <b-form-textarea
        id="endContractReason"
        placeholder="Enter Reason"
        v-model="endContractReason"
        :state="!$v.endContractReason.required && $v.endContractReason.$dirty ? false : null"
        rows="6"
      />

      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <div class="d-flex justify-content-end">
      <b-btn
        variant="danger"
        class="ml-2"
        style="width: 150px"
        @click.prevent="confirmEndContract"
        href="#"
        data-toggle="modal"
      >
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i> <span v-else>End Contract</span></b-btn
      >
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { USER_ROLE_TYPES } from '../../../common/constants';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'EndContractModal',

  props: {
    entity: Object,
    partnership: Object,
    id: Number,
    showModal: { type: Boolean, default: false },
  },

  data() {
    return {
      isLoading: false,
      endContractReason: '',
      USER_ROLE_TYPES,
    };
  },
  validations() {
    return {
      endContractReason: { required },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.endContractModal.show();
      }
    },
  },
  methods: {
    ...mapActions('hire', ['deleteHire']),
    get,

    async confirmEndContract() {
      this.isLoading = true;
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const data = { contract_end_reason: this.endContractReason };
        try {
          await this.deleteHire({ id: this.id, data });
          this.makeToast({ variant: 'danger', msg: 'Contract Ended!' });
          this.hideModalManual();
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }

      this.isLoading = false;
    },

    hideModal() {
      this.$emit('hide');
    },

    hideModalManual() {
      this.$refs.endContractModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
