<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">
      Filter {{ getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL ? 'Students' : 'Candidates' }}
    </div>
    <div class="sidebar-heading">Programs</div>
    <div class="sidebar-block">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="filters.enrolled_program__title"
          :reduce="(offered_programs) => offered_programs.title"
          placeholder="Select Offered Program"
          :options="allPrograms"
          :loading="areProgramsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>
    <span v-if="getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL">
      <div class="sidebar-heading">Student Profile Status</div>
      <div class="sidebar-block">
        <b-form-group>
          <v-select
            id="offered_programs"
            class="form-control v-select-custom"
            label="text"
            v-model="filters.student_status"
            :reduce="(status) => status.value"
            placeholder="Select status"
            :options="allStudentProfileStatuses"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template slot="option" slot-scope="option">
              <span>{{ option.text }}</span>
            </template>
          </v-select>
        </b-form-group>

        <span v-if="filters.student_status">Note: {{ $t(`profileStatuses.${filters.student_status}`) }}</span>
      </div>
    </span>
    <div class="sidebar-heading">Location</div>
    <div class="sidebar-block">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Zip code" v-model="filters.zipCode" />
      </div>
      <!-- <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.zipCodeRange"
          :options="zipCodeRangeOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div> -->
    </div>

    <div class="sidebar-heading">Certifications</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="certifications-checks"
          v-model="filters.certifications"
          :options="certificationOptions"
          stacked
        ></b-form-checkbox-group>

        <b-form-checkbox-group
          id="piis-checks"
          v-model="filters.piis"
          :options="piisOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Records</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="records-checks"
          v-model="filters.records"
          :options="recordOptions"
          stacked
        ></b-form-checkbox-group>
        <!-- </b-form-group>
      <b-form-group> -->
      </b-form-group>
    </div>

    <div class="sidebar-heading">EXPERIENCE</div>
    <div class="sidebar-block">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.experience"
          :options="workExpOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
    </div>

    <div class="sidebar-heading">Availability</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="availability-checks"
          v-model="filters.availability"
          :options="availabilityOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Interested Facility Type</div>
    <div class="sidebar-block">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.facilityType"
          :options="facilityTypeOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
    </div>

    <div class="sidebar-heading">Preferred Work Day</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="work-day-checks"
          v-model="filters.workDay"
          :options="workDayOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">Preferred Work Time</div>
    <div class="sidebar-block">
      <b-form-group>
        <b-form-checkbox-group
          id="work-time-checks"
          v-model="filters.workTime"
          :options="workTimeOptions"
          stacked
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <div class="sidebar-heading">LANGUAGE PREFERENCE</div>
    <div class="sidebar-block">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.langPreferences"
          :options="langOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
      <!-- <b-form-select v-model="filters.langPreferences" :options="langOptions" multiple :select-size="4"></b-form-select> -->
    </div>

    <div class="sidebar-heading">GENDER</div>
    <div class="sidebar-block">
      <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.gender"
          :options="genderOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div>
    </div>
    <!-- <p class="update-btn">
      <a href="" class="btn btn-primary">Update</a>
    </p> -->
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';
import moment from 'moment';
import { map, some, get } from 'lodash';

import { getLangOptions } from '../../../common/utils';
import { USER_ROLE_TYPES } from '../../../common/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CandFiltersSidebar',

  components: { FmvSidebar },

  data() {
    return {
      USER_ROLE_TYPES,

      filters: {
        zipCode: null,
        zipCodeRange: 30,

        certifications: [],
        records: [],
        piis: [],
        availability: [],
        facilityType: null,
        workDay: [],
        workTime: [],
        langPreferences: null,

        experience: null,
        gender: null,
        enrolled_program__title: null,
        student_status: null,
      },

      workExpOptions: [
        { value: null, text: 'Any' },
        { value: '0-1', text: '0-1 year' },
        { value: '1-2', text: '1-2 years' },
        { value: '3-4', text: '3-4 years' },
        { value: '5-6', text: '5-6 years' },
        { value: '7+', text: '7+ years' },
      ],
      genderOptions: [
        { value: null, text: 'Any' },
        { value: 'male', text: 'Male' },
        { value: 'female', text: 'Female' },
        { value: 'other', text: 'Other' },
      ],
      zipCodeRangeOptions: [{ value: 30, text: 'Within 30 Miles' }],
      langOptions: [],

      certificationOptions: [
        { value: 'cna_license_no', text: 'CNA License' },
        { value: 'cma_license_no', text: 'CMA License' },
      ],

      // contains pii certifications
      piisOptions: [
        { value: 'cpr', text: 'CPR License' },
        { value: 'first_aid', text: 'First Aid' },
        { value: 'bls', text: 'Basic Life Support' },
      ],
      recordOptions: [
        { value: 'covid_vacc', text: 'COVID-19 Vaccination' },
        { value: 'tb_test', text: 'TB Test' },
        { value: 'vehicle_reg', text: 'Vehicle Registration' },
      ],

      availabilityOptions: [
        { value: 'full_time', text: 'Full-Time' },
        { value: 'part_time', text: 'Part-Time' },
      ],
      workDayOptions: [],
      workTimeOptions: [
        { value: 'day', text: 'Day' },
        { value: 'evening', text: 'Evening' },
        { value: 'overnight', text: 'Overnight' },
      ],
      facilityTypeOptions: [
        { value: null, text: 'Any' },
        { value: 'adult_day_care', text: 'Adult day care' },
        { value: 'assisted_living', text: 'Assisted living' },
        { value: 'hospital', text: 'Hospital' },
        { value: 'hospice_care', text: 'Hospice care' },
        { value: 'home_care', text: 'Home care' },
        { value: 'longterm_care', text: 'Long-term care' },
        { value: 'other', text: 'Other' },
      ],
      allPrograms: [],
      areProgramsLoading: false,
      allStudentProfileStatuses: [
        { value: 'active', text: 'Active' },
        { value: 'inactive', text: 'Inactive' },
        { value: 'incomplete_profile', text: 'Incomplete' },
        { value: 'hidden_profile', text: 'Hidden' },
      ],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    ...mapActions('student', ['updateCandidateEmpFilters']),
    ...mapActions('program', ['getAllPrograms']),
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms({ ordering: 'id' });
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateCandidateEmpFilters({ filters });
      },
    },
  },

  async mounted() {
    if (some(this.$route.query)) {
      this.filters.zipCode = this.$route.query.zipcode;

      this.filters.certifications = get(this.$route.query, 'certifications')
        ? get(this.$route.query, 'certifications').split(',')
        : [];
      this.filters.records = get(this.$route.query, 'records') ? get(this.$route.query, 'records').split(',') : [];
      this.filters.piis = get(this.$route.query, 'piis') ? get(this.$route.query, 'piis').split(',') : [];
      this.filters.availability = get(this.$route.query, 'desired_work_type')
        ? get(this.$route.query, 'desired_work_type').split(',')
        : [];
      this.filters.facilityType = this.$route.query.facility_type;
      this.filters.workDay = get(this.$route.query, 'desired_work_day')
        ? get(this.$route.query, 'desired_work_day').split(',')
        : [];
      this.filters.workTime = get(this.$route.query, 'desired_work_time')
        ? get(this.$route.query, 'desired_work_time').split(',')
        : [];
      this.filters.langPreferences = this.$route.query.lang_preference;

      this.filters.experience = this.$route.query.work_experience;
      this.filters.gender = this.$route.query.user__gender;
      this.filters.enrolled_program__title = this.$route.query.enrolled_program__title;
      this.filters.student_status = this.$route.query.student_status;
    }
    this.fetchPrograms();
    this.langOptions = [{ value: null, text: 'Any' }, ...getLangOptions('text')];
    this.workDayOptions = map(moment.weekdays(), (day) => ({ value: day.toLowerCase(), text: day }));
  },
};
</script>
