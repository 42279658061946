<template>
  <b-modal ref="programsModal" hide-footer :title="modalTitle" centered lazy scrollable @hide="hideModal">
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div v-for="item in [1, 2, 3]" :key="item" class="d-flex px-1">
          <div class="w-100 ml-2">
            <b-skeleton width="60%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </div>
        </div>
      </template>
      <i v-if="!programs.length">No programs yet.</i>
      <div v-for="program in programs" :key="program.id" class="d-flex event-mod-item pt-3 px-1" v-else>
        <div class="d-flex align-items-center">
          <strong class="ml-2">{{ program.title }} </strong>
        </div>
      </div>
    </b-skeleton-wrapper>
  </b-modal>
</template>

<script>
import { get } from 'lodash';

import DefaultImg from '@/assets/images/placeholders/cna_career.jpg';

export default {
  name: 'ShowProgramsModal',

  components: {},

  props: {
    showModal: { type: Boolean, default: false },
    programs: { type: Array, default: () => [] },
  },

  data() {
    return {
      DefaultImg,

      modalTitle: '',
      states: [],
      isLoading: false,
    };
  },

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.modalTitle = `Offered Programs`;
        this.isLoading = true;
        this.$refs.programsModal.show();
        this.isLoading = false;
      }
    },
  },

  methods: {
    get,
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.programsModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
