<template>
  <div :class="containerClass">
    <page-header
      title="School Profile"
      :container-class="null"
      class="mb-32pt"
      button-label="Show Public View"
      :button-path="{ name: 'school-profile' }"
      button-variant="secondary"
      infoText="Manage your school's profile."
    />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Basic School Info -->
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <!-- <user-avatar slot="aside" rounded="lg" size="lg" :isLoading="isLogoUploading" :file-url="owner.logoUrl">
          </user-avatar> -->

          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img :src="school.logo_url" class="img-fluid" width="40" alt="Logo" v-else-if="school.logo_url" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isUploading.logo"
            :isRequired="!school.logo_url"
            :placeholder="$t('schoolMsgs.logoDesc')"
          />
        </b-media>
      </b-form-group>
      <b-form-group label-for="small_logo_url" class="row-align-items-center">
        <label class="form-label">
          Small Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
            "
            >info</i
          >
        </label>

        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isUploading.smallLogo">...</span>
            <b-img
              :src="school.small_logo_url"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-else-if="school.small_logo_url"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'smallLogo', 'small_logo_url')"
            :isUploading="isUploading.smallLogo"
            :isRequired="!school.small_logo_url"
            placeholder="Select School's Small Logo Picture"
          />
        </b-media>
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.name')" label-for="name" label-class="form-label">
        <b-form-input id="name" :placeholder="$t('schoolMsgs.namePlaceholder')" v-model="school.name" required />
      </b-form-group>

      <b-form-group label="Contact email" label-for="email" label-class="form-label">
        <b-form-input id="email" placeholder="Enter school's contact email" v-model="school.email" type="email" />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="school.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.website')" label-for="website" label-class="form-label">
        <b-form-input id="website" :placeholder="$t('schoolMsgs.websitePlaceholder')" v-model="school.websiteUrl" />
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.subdomain')" label-for="subdomain" label-class="form-label">
        <b-form-input id="subdomain" :value="school.subdomain" disabled />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="school.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="school.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="school.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="school.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="school.country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!school.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <!-- Other Details -->

      <b-form-group label="Short Bio" label-for="short_bio" label-class="form-label">
        <b-form-textarea
          id="short_bio"
          placeholder="Enter school's short/summarized bio."
          :rows="5"
          v-model="school.short_bio"
        />
      </b-form-group>
      <b-form-group label="Bio" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="school.bio"
          placeholder="Enter school's bio"
        />
      </b-form-group>

      <b-form-group
        :label="$t('schoolMsgs.profileBgImage')"
        label-for="profileBgImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <image-uploader
            @image="e => setImage(e, 'profileBgImage', 'profile_bg_img_url')"
            :isUploading="isUploading.profileBgImage"
            :isRequired="false"
            :placeholder="$t('schoolMsgs.profileBgImageDesc')"
          />
        </b-media>
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.homeTitle')" label-for="pp_home_title" label-class="form-label">
        <b-form-input id="pp_home_title" v-model="school.pp_home_title" required />
      </b-form-group>

      <b-form-group :label="$t('schoolMsgs.homeDescription')" label-for="pp_home_description" label-class="form-label">
        <b-form-input id="pp_home_description" v-model="school.pp_home_description" required />
      </b-form-group>

      <b-form-group
        :label="$t('schoolMsgs.testimonialTitle')"
        label-for="pp_testimonial_title"
        label-class="form-label"
      >
        <b-form-input id="pp_testimonial_title" v-model="school.pp_testimonial_title" required />
      </b-form-group>
      <b-form-group :label="$t('schoolMsgs.publicCTA')" label-for="pp_cta_link_text" label-class="form-label">
        <input id="pp_cta_link_text" v-model="school.pp_cta_link_text" class="form-control" required maxlength="15" />
      </b-form-group>
      <b-form-group
        :label="$t('schoolMsgs.testimonialDescription')"
        label-for="pp_testimonial_description"
        label-class="form-label"
      >
        <b-form-input id="pp_testimonial_description" v-model="school.pp_testimonial_description" required />
      </b-form-group>

      <div class="row">
        <b-form-group
          :label="$t('schoolMsgs.primaryColor')"
          label-for="primaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.primaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.secondaryColor')"
          label-for="secondaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.secondaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.darkColor')"
          label-for="darkColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="school.darkColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
      </div>

      <page-separator title="Careers Settings" />

      <b-form-group label-class="form-label">
        <b-form-checkbox v-model="school.std_portal_uni" name="check-button" switch>
          Show university career paths to students?
        </b-form-checkbox>
      </b-form-group>

      <b-form-group label="Offered Programs" label-for="offered_programs" label-class="form-label">
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="school.offered_programs"
          :reduce="offered_programs => offered_programs.id"
          placeholder="Select Offered Program"
          :options="allPrograms"
          :loading="areProgramsLoading"
          multiple
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!school.offered_programs" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>

      <!-- <b-form-group label="Program Tags" label-for="tags" label-class="form-label">
        <multiselect
          v-model="tags"
          class="multi"
          placeholder="Add tags with the enter key"
          label="title"
          :options="tagOptions"
          :multiple="true"
          :taggable="true"
          @tag="addTag"
          track-by="id"
          @remove="removeTag"
        ></multiselect>
      </b-form-group> -->

      <page-separator title="Employer Partnership" />

      <div class="text-50 w-100 mb-3">Manage employer page on public student center site</div>
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Banner Image
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isBannerImgLoading">...</span>
            <b-img :src="school.bannerImg" class="img-fluid" width="40" alt="Logo" v-else-if="school.bannerImg" />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'bannerImg', 'bannerImg')"
            :isUploading="isBannerImgLoading"
            :isRequired="false"
            placeholder="Select best school image"
          />
        </b-media>
      </b-form-group>

      <b-form-group label="Banner Title" label-for="emp_pship_banner_title" label-class="form-label">
        <b-form-input id="emp_pship_banner_title" placeholder="Enter Title" v-model="school.emp_pship_banner_title" />
      </b-form-group>
      <b-form-group label="Short Description" label-for="emp_pship_short_desc" label-class="form-label">
        <b-form-textarea
          id="emp_pship_short_desc"
          placeholder="Enter short description."
          :rows="5"
          v-model="school.emp_pship_short_desc"
        />
      </b-form-group>
      <b-form-group label="Banner Description" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="school.emp_pship_desc"
          placeholder="Enter banner's description"
        />
      </b-form-group>

      <!-- <b-form-group
        label="Partnership Overview Thumbnail"
        label-for="partnershipImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <b-form-file
            id="partnershipImage"
            placeholder="Select image"
            :browse-text="$t('generalMsgs.browse')"
            v-model="selectedUploadFile.partnershipImage"
            @input="uploadFile('partnershipImage')"
            :disabled="isUploading.isPartnershipImageUploading"
            accept="image/*"
          />
        </b-media>
      </b-form-group> -->

      <b-form-group
        label="Partnership Overview Video"
        label-for="partnership_video"
        label-class="form-label"
        class="row-align-items-center"
        v-if="!school.partnershipOverviewVideoUrl"
      >
        <b-media class="align-items-center" vertical-align="center">
          <b-form-file
            id="partnership_video"
            placeholder="Upload a video to showcase on employer overview"
            :browse-text="$t('generalMsgs.browse')"
            v-model="selectedUploadFile.partnershipOverviewVideo"
            @input="uploadFile('partnershipOverviewVideo')"
            :disabled="isUploading.partnershipOverviewVideo"
            accept="video/mp4, video/webm"
          />
        </b-media>
      </b-form-group>

      <b-form-group
        v-if="school.partnershipOverviewVideoUrl"
        label="Partnership Overview Video Preview"
        label-for="partnership_video"
        label-class="form-label"
      >
        <div class="d-flex justify-content-end mb-3">
          <b-btn variant="danger" @click="school.partnershipOverviewVideoUrl = ''"> Remove </b-btn>
        </div>
        <video width="100%" height="200" controls>
          <source :src="school.partnershipOverviewVideoUrl" type="video/mp4" />
        </video>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>{{ $t('update') }}</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '../../../components/Ui/PageHeader.vue';
import Page from '../../../components/Page.vue';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import { DEFAULT_FAVICON } from '../../../common/constants';
import Editor from '@tinymce/tinymce-vue';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
// import Multiselect from 'vue-multiselect';
import { uuid } from 'vue-uuid';
export default {
  components: {
    // Multiselect,
    PageHeader,
    FmvAvatar,
    VSwatches,
    Editor,
    ImageUploader,
    PageSeparator
  },
  extends: Page,

  data() {
    return {
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96']
      ],
      title: 'School Profile',

      owner: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      school: {
        name: '',
        email: '',
        phone: '',
        websiteUrl: '',
        zipcode: '',
        address: '',
        city: '',
        state: '',
        subdomain: '',
        small_logo_url: null,
        country: 'United States',
        logo_url: null,
        profile_bg_img_url: null,
        partnershipOverviewVideoUrl: null,
        partnershipImageUrl: null,
        primaryColor: '#00b3e6',
        secondaryColor: '#868e96',
        darkColor: '#214e6f',
        bio: '',
        short_bio: '',
        pp_home_title: 'Welcome To Career Services',
        pp_home_description:
          'Our School offers a variety of career services to their student including: job listings, resume building, interview preparation, career exploration, and more.',
        pp_testimonial_title: 'Testimonials',
        pp_testimonial_description: 'Read about the career success others have had after completing our program.',

        offered_programs: [],

        pp_cta_link_text: 'Join Transition',
        emp_pship_banner_title: 'Employer Partnerships',
        bannerImg: null,
        emp_pship_desc: `welcomes collaboration with healthcare employers in the local community. Our partnerships are a collaborative effort to help students explore career choices, make informed career decisions, and begin applying their skills into the workplace.

If you are seeking to connect with our students, offer sponorships, and participate in hiring/career events; please view the following steps on how to request to become a partner.`,
        emp_pship_short_desc: '',
        std_portal_uni: true
      },
      allPrograms: [],
      tags: [],
      tagOptions: [],
      areTagsLoading: false,
      countryOptions: [],
      stateOptions: [],
      errorStates: {
        password: null,
        ownerEmail: null
      },
      passErrorCode: null,
      isPassCopied: false,

      isLoading: false,
      isLogoUploading: false,
      isProfileBgImgUploading: false,
      isBannerImgLoading: false,
      isUploading: {
        logo: false,
        profileBgImage: false,
        smallLogo: false,
        bannerImg: false,
        partnershipOverviewVideo: false,
        partnershipImage: false
      },
      selectedUploadFile: {
        logo: null,
        profileBgImage: null,
        smallLogo: null,
        bannerImg: null,
        partnershipOverviewVideo: null,
        partnershipImage: null
      },
      uploadPercent: { logo: 0, profileBgImage: 0, smallLogo: 0, bannerImg: 0 },
      uploadCancelTokenSource: { logo: null, profileBgImage: null, smallLogo: null, bannerImg: null },
      areProgramsLoading: false,
      removeTags: []
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        {
          text: 'School Profile',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || Object.values(this.isUploading).some(val => val);
    }
  },

  methods: {
    ...mapActions('school', ['getSchool', 'updateMySchoolProfile', 'getSchoolImageUploadPresignedUrl', 'getAllTags']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('program', ['getAllPortalPrograms']),
    ...mapActions('navbarConfig', ['updateNavbarBrandDetails']),
    async fetchTags() {
      this.areTagsLoading = true;
      try {
        const res = await this.getAllTags({});
        this.tagOptions = res.data;
      } catch (err) {
        // Handle error if needed
      }

      this.areTagsLoading = false;
    },
    removeTag(op) {
      this.removeTags.push(op.id);
    },
    addTag(newTag) {
      const tag = {
        title: newTag,
        id: uuid.v4()
      };
      this.tagOptions.push(tag);
      this.tags.push(tag);
    },
    setImage(file, image, profileUrlField = 'logo_url') {
      this.selectedUploadFile[image] = file;
      this.uploadFile(image, profileUrlField);
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      const response = await this.getAllPortalPrograms({});
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },

    async onSubmit() {
      this.isLoading = true;
      try {
        await this.updateMySchoolProfile({
          name: this.school.name,
          contact_email: this.school.email,
          phone: this.school.phone,
          website_url: this.school.websiteUrl,
          zipcode: this.school.zipcode,
          address: this.school.address,
          city: this.school.city,
          state: this.school.state,
          country: this.school.country,
          small_logo_url: this.school.small_logo_url,
          logo_url: this.school.logo_url,
          profile_bg_img_url: this.school.profile_bg_img_url,
          primary_color: this.school.primaryColor,
          secondary_color: this.school.secondaryColor,
          dark_color: this.school.darkColor,
          bio: this.school.bio,
          short_bio: this.school.short_bio,
          pp_home_title: this.school.pp_home_title,
          pp_home_description: this.school.pp_home_description,
          pp_testimonial_title: this.school.pp_testimonial_title,
          pp_testimonial_description: this.school.pp_testimonial_description,
          emp_pship_banner_title: this.school.emp_pship_banner_title,
          emp_pship_desc: this.school.emp_pship_desc,
          offered_programs: this.school.offered_programs,
          emp_pship_banner_img: this.school.bannerImg,
          pp_cta_link_text: this.school.pp_cta_link_text,
          emp_pship_short_desc: this.school.emp_pship_short_desc,
          std_portal_uni: this.school.std_portal_uni,
          pship_overview_video: this.school.partnershipOverviewVideoUrl,
          pship_overview_thumbnail: this.school.partnershipImageUrl,
          tags: this.tags,
          tags_to_remove: this.removeTags
        });
        this.makeToast({ variant: 'success', msg: 'School Profile Updated' });
        document.documentElement.style.setProperty('--primary-color', this.school.primaryColor);
        document.documentElement.style.setProperty('--secondary-color', this.school.secondaryColor);
        document.documentElement.style.setProperty('--dark-color', this.school.darkColor);
        const favicon = document.getElementById('favicon');

        favicon.href = get(this.school, 'small_logo_url') || DEFAULT_FAVICON;
        this.updateNavbarBrandDetails();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    async uploadFile(fileType, profileUrlField = 'logo_url') {
      this.uploadCancelTokenSource[fileType] = axios.CancelToken.source();
      this.isUploading[fileType] = true;

      try {
        const urlResp = await this.getSchoolImageUploadPresignedUrl({
          file_name: this.selectedUploadFile[fileType].name,
          content_type: this.selectedUploadFile[fileType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.selectedUploadFile[fileType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[fileType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[fileType].token
          }
        });
        if (fileType === 'partnershipOverviewVideo') {
          this.school.partnershipOverviewVideoUrl = urlResp.upload_url.split('?')[0];
        } else if (fileType === 'partnershipImage') {
          this.school.partnershipImageUrl = urlResp.upload_url.split('?')[0];
        } else {
          this.school[profileUrlField] = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.selectedUploadFile[fileType] = null;
      }

      this.uploadCancelTokenSource[fileType] = null;
      this.uploadPercent[fileType] = 0;

      this.isUploading[fileType] = false;
    },

    async fetchSchoolProfile() {
      this.isLoading = true;
      try {
        const resp = (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;
        this.school.name = resp.name;
        this.school.email = resp.contact_email;
        this.school.phone = resp.phone;
        this.school.websiteUrl = resp.website_url;
        this.school.zipcode = resp.zipcode;
        this.school.address = resp.address;
        this.school.city = resp.city;
        this.school.state = resp.state;
        this.school.country = resp.country;
        this.school.logo_url = resp.logo_url;
        this.school.small_logo_url = resp.small_logo_url;
        this.school.subdomain = resp.subdomain.subdomain_url;
        this.school.profile_bg_img_url = resp.profile_bg_img_url;
        this.school.primaryColor = resp.primary_color;
        this.school.secondaryColor = resp.secondary_color;
        this.school.darkColor = resp.dark_color;
        this.school.bio = resp.bio;
        this.school.short_bio = resp.short_bio;
        this.school.pp_home_title = resp.pp_home_title;
        this.school.pp_home_description = resp.pp_home_description;
        this.school.pp_testimonial_title = resp.pp_testimonial_title;
        this.school.pp_testimonial_description = resp.pp_testimonial_description;
        this.school.emp_pship_banner_title = resp.emp_pship_banner_title;
        this.school.emp_pship_desc = resp.emp_pship_desc;
        this.school.bannerImg = resp.emp_pship_banner_img;
        this.school.offered_programs = resp.offered_programs.map(prg => prg.id);
        this.school.emp_pship_short_desc = resp.emp_pship_short_desc;
        this.school.pp_cta_link_text = resp.pp_cta_link_text;
        this.school.std_portal_uni = resp.std_portal_uni;
        this.school.partnershipOverviewVideoUrl = resp.pship_overview_video;
        this.school.partnershipImageUrl = resp.pship_overview_thumbnail;
        this.tags = resp.tags;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        // this.$router.push({ name: 'not-found' });
      }

      this.isLoading = false;
    },
    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name
        }))
      );
    }
  },

  async mounted() {
    this.fetchTags();
    this.fetchPrograms();
    this.fillInOptions();
    this.fetchSchoolProfile();
  }
};
</script>
