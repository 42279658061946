<template>
  <fmv-drawer id="candidates-filters" align="end">
    <cand-filters-sidebar></cand-filters-sidebar>
  </fmv-drawer>
</template>

<script>
import { FmvDrawer } from 'fmv-layout';
import CandFiltersSidebar from './CandFiltersSidebar.vue';

export default {
  name: 'CandidateFilters',

  components: { FmvDrawer, CandFiltersSidebar },

  data() {
    return {};
  },

  computed: {},

  methods: {},

  async mounted() {},
};
</script>
