<template>
  <div>
    <div :class="pending ? '' : 'page-section'">
      <div :class="pending ? '' : containerClass">
        <h3 v-if="!pending" class="text-center">
          LET'S GET YOU ON THE PATH TO SUCCESS
          <i
            class="material-icons icon-16pt clickable-item"
            v-b-popover.hover.top="`Guide of all major functionalities of transition organization portal.`"
            >info</i
          >
        </h3>

        <div v-if="pending ? pendingGuides.length : setupGuideData.length" class="card">
          <div class="card-body">
            <div v-for="(guide, index) in pending ? pendingGuides : setupGuideData" :key="guide.id">
              <guide-row
                v-if="!pending || pending !== guide.status"
                :title="guide.title"
                :description="guide.description"
                :status="guide.status"
                :isLoading="isLoading"
                :redirect-to="guide.redirectTo"
                :icon="guide.icon"
                :btn-text="guide.btnText"
              />
              <hr v-if="index + 1 < (pending ? pendingGuides.length : setupGuideData.length)" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import GuideRow from './GuideRow.vue';
import Page from '../../../components/Page.vue';

export default {
  components: { GuideRow },
  extends: Page,
  name: 'OrgSetupGuide',
  props: { pending: { type: Boolean, default: false }, pendingCount: { type: Number, default: () => 3 } },

  data() {
    return {
      title: 'Setup Guide',
      isLoading: false,

      setupStatuses: {
        employers_exists: true,
        scholarship_exists: true,
        application_ques_exists: true,
        job_exists: true,
        allow_share_credits: true,
        init_site_updated: false,
      },
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true },
      ];
    },
    pendingGuides() {
      const pendingGuides = this.setupGuideData.filter((guide) => this.pending !== guide.status);
      const showGuides = this.setupGuideData
        .filter((guide) => this.pending !== guide.status)
        .slice(0, this.pendingCount);

      this.$emit('pendingGuides', pendingGuides.length - this.pendingCount);
      return showGuides;
    },
    setupGuideData() {
      return [
        {
          id: 1,
          title: 'Invite Employers',
          description:
            'Send Invite links to employers to get them onboarded to establish partnerships with schools, unlock candidates and post jobs!',
          status: this.setupStatuses.employers_exists,
          redirectTo: 'manage-org-employers',
          icon: 'business',
          btnText: 'Establish',
        },
        {
          id: 2,
          title: 'Offer Sponsorships',
          description: `Present unique sponsorship opportunities to candidates enrolling in our partnered training providers, enhancing your organization's visibility and reputation.`,
          status: this.setupStatuses.scholarship_exists,
          redirectTo: 'scholarships-management-list',
          icon: 'stars',
          btnText: 'Offer',
        },
        {
          id: 7,
          title: 'Add Application Questions',
          description:
            'Integrate qualifying questions for candidates interested in your opportunities, ensuring an effective screening process.',
          status: this.setupStatuses.application_ques_exists,
          redirectTo: 'manage-questions',
          icon: 'question_answer',
          btnText: 'Add',
        },
        {
          id: 3,
          title: 'List Jobs',
          description:
            'Display your job openings on our platform, reaching a broad spectrum of potential candidates with diverse skills and backgrounds.',
          status: this.setupStatuses.job_exists,
          redirectTo: 'jobs-management-list',
          icon: 'work',
          btnText: 'List',
        },
        // {
        //   id: 4,
        //   title: 'Share credits',
        //   description:
        //     'Let your employers share your job credits and transition tokens so that they can unlock more candidates, make partnerships and post jobs and sponsorships',
        //   status: this.setupStatuses.allow_share_credits,
        //   redirectTo: 'manage-purchase-tokens',
        //   btnText: 'Add',
        // },
        {
          id: 5,
          title: 'Configure Career Site',
          description: `Customize your career site directly to display sponsorships, jobs, partnered schools and associated programs to attract a pipeline of skilled healthcare students.`,
          status: this.setupStatuses.init_site_updated,
          redirectTo: 'basic-config',
          icon: 'settings',
          btnText: 'Configure',
        },
      ];
    },
  },

  methods: {
    ...mapActions('organization', ['getOrgSetupGuideStatuses']),

    async fetchSetupGuideStatus() {
      this.isLoading = true;
      try {
        this.setupStatuses = (await this.getOrgSetupGuideStatuses()).data;
      } catch (_err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.fetchSetupGuideStatus();
  },
};
</script>
