<template>
  <b-modal ref="StdHireModal" hide-footer title="Select Your preference" centered lazy @hide="hideModal" size="lg">
    <h2 class="text-center mb-0" style="font-weight: bold">Select Hiring Options</h2>

    <page-separator title="" />

    <div class="alert alert-light border border-6 border-secondary rounded p-4" role="alert" style="">
      <div class="row">
        <div class="col-lg-12 d-flex">
          <div class="flex" style="max-width: 100%">
            <div class="">
              <h3 class="text-dark" style="font-size: 19px">In-House Recruitment</h3>
              <p class="text-90 mb-3" style="font-size: 14px">
                 Select this option when hiring students who are associated <b>only with your organization</b>. No additional reference fee / hiden charges included while hiring internally.
              </p>

              <div class="d-flex justify-content-center">
                <b-btn :to="{ name: 'make-a-hire' , query: { fromOrganization: true }  }" exact variant="primary">
                  Choose
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div class="alert alert-light border border-6 border-secondary rounded p-4" role="alert" style="">
      <div class="row">
        <div class="col-lg-12 d-flex">
          <div class="flex" style="max-width: 100%">
            <div class="">
              <h3 class="text-dark" style="font-size: 19px">External recruitment</h3>
              <p class="text-90 mb-3" style="font-size: 14px">
                Select this option when hiring students who are associated<b> with partnered schools</b>. Additional reference fee / hiden charges included while hiring externally.
              </p>

              <div class="d-flex justify-content-center">
                <b-btn :to="{ name: 'make-a-hire' }" exact variant="primary">
                  Choose
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLE_TYPES } from '@/common/constants';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'SchoolsModal',
  components: { PageSeparator },

  props: {
    showModal: { type: Boolean, default: false },
    scholarship: { type: Object, default: () => {} },
    schools: { type: Array, default: () => [] },
  },

  data() {
    return {
      isLoading: false,
      subdomain: '',
      demoEmail: '',
      demoPassword: '',

      showPass: false,
      selectedOption: 'Select an option'
    };
  },

  validations() {
    return {};
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    ...mapActions('school', ['getSchool', 'getSchoolDemoStdAccount']),
    get,
    selectOption(option) {
      this.selectedOption = option;
    },

    async fetchSchool() {
      try {
        const resp = (await this.getSchool(this.getLoggedInUser.linked_entity.id)).data;

        this.subdomain = resp.subdomain.subdomain_url;
      } catch (err) {
        //  USE ERROR
      }

      this.isLoading = false;
    },

    async fetchDemoStdAccount() {
      try {
        const resp = (await this.getSchoolDemoStdAccount()).data;
        this.demoEmail = resp.demo_email;
        this.demoPassword = resp.demo_password;
      } catch (err) {
        //  USE ERROR
      }

      this.isLoading = false;
    },

    async hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.StdHireModal.hide();
      this.hideModal();
    },

    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.makeToast({ variant: 'success', msg: 'Copied to clipboard.' });
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.StdHireModal.show();
      }
    },
  },
  async mounted() {
    if (get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL) {
      this.fetchDemoStdAccount();
      this.fetchSchool();
    }
  },
};
</script>
