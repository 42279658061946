<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `List of employers associated with your organization.`
          : ''
      "
    />
    <div class="page-section">
      <div :class="containerClass">
        <div
          class="card bg-primary p-2"
          style="color: white"
          v-if="get(getLoggedInUser, 'role') === USER_ROLES.ORGANIZATION"
        >
          <div class="d-flex justify-content-center align-items-center p-2" style="font-size: 16px">
            <strong> Invite Employer </strong>
          </div>

          <div class="p-2 mb-4">
            Use your invite link below to invite employers to request to partner with your Student Center. Accepted
            partnership requests will give employers the ability to view your students, offer sponsorships, and post
            jobs!
          </div>
          <b-alert variant="soft-light px-12pt" show>
            <div class="d-flex align-items-center justify-content-between">
              <span class="text-black-100"
                ><strong>{{ inviteURL }}</strong></span
              >

              <span v-if="isDomainCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
              <a href="#" @click.prevent="copyDomain" v-else v-b-popover.hover.top="'Copy Invite Link'">
                <md-icon>content_copy</md-icon>
              </a>
            </div>
          </b-alert>
        </div>

        <div class="card mb-0">
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    :placeholder="'Search Employers'"
                    v-model="searchTerm"
                    @input="onSearch"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>
            </div>
          </div>
          <!-- Employers Table -->
          <b-table
            :fields="tableColumns"
            :items="employers"
            :busy="isLoading"
            head-variant="light"
            class="table-nowrap"
            hover
            responsive
            no-local-sorting
            @sort-changed="onSortChange"
            @row-clicked="openDetailPage"
          >
            <template #cell(logo)="data">
              <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                <b-img :src="data.item.logo_url" class="img-fluid" width="40" alt="Logo" v-if="data.item.logo_url" />
                <md-icon v-else>business</md-icon>
              </fmv-avatar>
            </template>

            <template #cell(name)="data"
              ><strong> {{ data.item.name }} </strong></template
            >

            <template #cell(admin)="data"
              >{{ data.item.main_admin.first_name }} {{ data.item.main_admin.last_name }}</template
            >

            <template #cell(address)="data">
              {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
              {{ data.item.country }}
            </template>
            <template #cell(referral_school)="data"
              ><span v-if="data.item.referral_school"> {{ data.item.referral_school.name }} </span
              ><span v-else class="d-flex justify-content-center">-</span></template
            >
            <!-- <template #cell(status)="data">
              <b-badge
                :class="
                  'border border-' +
                  (data.item.is_profile_visible ? `success text-success` : `secondary text-secondary`)
                "
                variant="none"
                >{{ data.item.is_profile_visible ? 'Active' : 'Paused' }}</b-badge
              >
            </template> -->

            <template #head(actions)="">
              <span></span>
            </template>
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'edit-org-employer',
                  params: { id: data.item.id },
                }"
                class="svg-icon mr-2"
                v-b-popover.hover.top
                :title="$t('generalMsgs.edit')"
              >
                <md-icon class="text-info">edit</md-icon>
              </router-link>
              <!-- <a
                href="#"
                @click.prevent="confirmDeleteEmployer(data.item.id)"
                v-b-popover.hover.top
                :title="$t('generalMsgs.remove')"
              >
                <md-icon class="text-danger">delete</md-icon>
                <i class="far fa-trash-alt text-danger" />
              </a> -->
            </template>
          </b-table>

          <!-- Footer Pagination -->
          <div class="card-footer">
            <pagination
              v-model="currentPage"
              :total-rows="totalEmployers"
              :per-page="perPage"
              @change="onPageChange"
              aria-controls="employers-table"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import MdIcon from '@/components/Ui/MdIcon.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLE_TYPES, USER_ROLES } from '@/common/constants';
import { formatDateTime, formatFullDate } from '@/common/utils';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { MdIcon, Pagination, FmvAvatar, PageHeader },
  extends: Page,

  name: 'EmployersList',

  data() {
    return {
      title: 'Employers',
      isDomainCopied: false,
      windowWidth: window.innerWidth,
      isLoading: false,
      isResendingAll: false,

      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,

      totalEmployers: 0,
      employers: [],

      searchTerm: '',
      USER_ROLE_TYPES,
      isDownloading: false,
      stdCount: {},
      showStdModal: false,
      employer: null,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('navbarConfig', ['getOrgReferId']),
    breadcrumb() {
      return [
        { text: 'Home', to: this.routes.home },
        { text: 'Manage Employers', active: true },
      ];
    },

    inviteURL() {
      return `${this.getClickableLink(process.env.VUE_APP_MAIN_DOMAIN)}/employer-signup/?org_refer_id=${
        this.getOrgReferId
      }`;
    },
    isMobileScreen() {
      return this.windowWidth <= 776;
    },
    tableColumns() {
      return [
        { key: 'logo', label: '' },
        { key: 'name', label: this.$t('employerMsgs.name'), tdClass: 'clickable-item' },
        // { key: 'status', label: this.$t('generalMsgs.status') },
        { key: 'admin', label: 'Admin', tdClass: 'clickable-item' },

        { key: 'main_admin.email', label: "Admin's Email", tdClass: 'clickable-item' },
        { key: 'phone', label: this.$t('employerMsgs.phone'), tdClass: 'clickable-item' },
        { key: 'address', label: this.$t('addressMsgs.address'), tdClass: 'clickable-item' },
        // { key: 'referral_school', label: 'Referral School' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
  },

  methods: {
    ...mapActions('fileDownload', ['downloadFile']),
    ...mapActions('employer', ['getAllEmployers']),
    formatDateTime,
    formatFullDate,
    get,

    openDetailPage(e) {
      this.$router.push({
        name: 'org-emp-public-profile',
        params: { id: e.id },
      });
    },

    async copyDomain() {
      try {
        await navigator.clipboard.writeText(this.inviteURL);
        this.isDomainCopied = true;

        setTimeout(() => (this.isDomainCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async downloadPdf(data) {
      this.isDownloading = true;
      try {
        const h = this.$createElement;
        this.makeToast({
          variant: 'primary',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
          toaster: 'b-toaster-bottom-left',
        });

        await this.downloadFile({ fileUrl: data.agreement_url, removeTimestamp: true });

        this.makeToast({
          variant: 'success',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
          toaster: 'b-toaster-bottom-left',
        });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isDownloading = false;
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },

    async fetchEmployers(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();
      let response = await this.getAllEmployers({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });

      this.employers = response.data.results;
      this.currentPage = pageNum;
      this.totalEmployers = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchEmployers(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchEmployers();
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    onSearch() {
      this.debouncedSearchEmployers(this);
    },

    debouncedSearchEmployers: debounce((vm) => {
      vm.fetchEmployers();
    }, 500),
  },

  async mounted() {
    this.fetchEmployers();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
