<template>
  <div :class="containerClass">
    <page-header
      title="Manage Student Dashboard"
      :container-class="null"
      class="mb-32pt"
      infoText="Manage your student's dashboard."
    />

    <div class="mb-5">
      <draggable class="row draggable-cursor" @start="dragging = true" :list="setupGuideData">
        <div
          class="d-flex align-items-center justify-content-center col-md-6"
          v-for="guide in setupGuideData"
          :key="guide.id"
        >
          <i class="fas fa-bars icon-16pt mr-4"></i>
          <DashboardCard
            :guide="guide"
            :setupStatuses="setupStatuses"
            :editable="true"
            style="height: 90%; width: 100%"
            @enable="enable"
          />
        </div>
      </draggable>
      <div class="d-flex">
        <b-btn variant="primary" style="width: 150px" class="btn-normal my-2 ml-auto" @click.prevent="updateOrder">
          Update
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import DashboardCard from '@/components/DashboardCard.vue';
import Page from '../../../components/Page.vue';
import PageHeader from '../../../components/Ui/PageHeader.vue';
import draggable from 'vuedraggable';

export default {
  extends: Page,
  name: 'SchoolSetupGuide',
  components: { PageHeader, DashboardCard, draggable },
  data() {
    return {
      title: 'Home',

      isLoading: false,
      setupStatuses: {
        uploaded_docs: true,
        question_asked: true,
        resume_build: true,
        payments_paid: true,
        practice_skills: '',
        jobs: '',
        employers: ''
      },
      status: null,
      windowWidth: window.innerWidth,
      showPrivacyModal: false,
      dragging: false,
      setupGuideData: []
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Setup Guide', active: true }
      ];
    },
    isMobileScreen() {
      return this.windowWidth <= 600;
    }
  },

  methods: {
    ...mapActions('school', ['updateStudentDashboard']),
    get,
    enable(item) {
      this.setupGuideData.map(guide => {
        if (guide.id === item.id) {
          return { ...guide, enable: !item.enable };
        } else {
          return guide;
        }
      });
    },
    async updateOrder() {
      this.dragging = false;
      const data = {
        std_dashboard_config: { dashboard_config: this.setupGuideData }
      };

      try {
        await this.updateStudentDashboard(data);
        this.makeToast({ variant: 'success', msg: 'Student Dashboard Updated' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  watch: {
    getLoggedInUser: {
      immediate: true,
      handler(value) {
        if (value) {
          this.setupGuideData = get(this.getLoggedInUser, 'linked_entity.std_dashboard_config.dashboard_config');
        }
      }
    }
  },
  async mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
