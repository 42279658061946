<template>
  <fmv-sidebar type="light" variant="white" class="py-16pt" wheel-propagation>
    <div class="sidebar-heading mb-4" style="font-size: 18px; font-weight: bolder">Filter Spotlights</div>
    <div class="sidebar-heading">Programs</div>
    <div class="sidebar-block">
      <b-form-group>
        <v-select
          id="offered_programs"
          class="form-control v-select-custom"
          label="title"
          v-model="filters.programs__title"
          :reduce="(offered_programs) => offered_programs.title"
          placeholder="Select Offered Program"
          :options="allPrograms"
          :loading="areProgramsLoading"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" v-bind="attributes" v-on="events" />
          </template>
          <template slot="option" slot-scope="option">
            <span>{{ option.title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </div>

    <div class="sidebar-heading">School</div>
    <div class="sidebar-block">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="School" v-model="filters.school__name" />
      </div>
      <!-- <div class="custom-select-icon">
        <b-form-select
          class="custom-select-icon__select"
          v-model="filters.zipCodeRange"
          :options="zipCodeRangeOptions"
        ></b-form-select>
        <span class="material-icons custom-select-icon__icon">sort</span>
      </div> -->
    </div>
  </fmv-sidebar>
</template>

<script>
import { FmvSidebar } from 'fmv-layout';
import { USER_ROLE_TYPES } from '@/common/constants';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SchoolFiltersSidebar',

  components: { FmvSidebar },

  data() {
    return {
      USER_ROLE_TYPES,

      filters: {
        school__name: null,
        programs__title: null,
      },

      zipCodeRangeOptions: [{ value: 30, text: 'Within 30 Miles' }],

      allPrograms: [],
      areProgramsLoading: false,
      stateOptions: [],
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  methods: {
    ...mapActions('school', ['updateSchoolsEmpFilters']),
    ...mapActions('stories', ['updateStoriesEmpFilters']),

    ...mapActions('program', ['getAllPrograms']),
    async fetchPrograms() {
      this.areProgramsLoading = true;

      const response = await this.getAllPrograms();
      this.allPrograms = response.data;
      this.areProgramsLoading = false;
    },
  },

  watch: {
    filters: {
      deep: true,
      handler(filters) {
        this.updateStoriesEmpFilters({ filters });
      },
    },
  },

  async mounted() {
    this.fetchPrograms();
  },
};
</script>
