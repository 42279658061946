var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-header',{attrs:{"title":_vm.title,"container-class":_vm.containerClass,"info-text":_vm.getLoggedInUser.role_type === _vm.USER_ROLE_TYPES.ORGANIZATION
        ? "List of all questions that you can link with a sponsorship form associated with a sponsorship whose in-app submission is enabled."
        : _vm.getLoggedInUser.role_type === _vm.USER_ROLE_TYPES.EMPLOYER
        ? 'Manage questions to be used in in-app sponsorship forms'
        : ''}}),_c('div',{staticClass:"page-section"},[_c('div',{class:_vm.containerClass},[_c('div',{staticClass:"card p-2",staticStyle:{"color":"black","background-color":"rgb(246 246 246)"}},[_c('div',{staticClass:"p-2"},[_c('p',[_vm._v("Associate each question with an application form to be used for in-app submission of sponsorships.")]),_c('p',[_c('b',[_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":{ name: 'scholarships-management-list' }}},[_vm._v(" Go to Sponsorships ")]),_vm._v(" > Open menu for desired opportunity > Select 'Manage Application Form' ")],1)]),_c('b',[_vm._v(" Note: ")]),_vm._v(" If In-app Submission is enabled for a sponsorship without changing application form, by default Introduction section with default questions will be displayed. ")])]),_c('div',{staticClass:"card mb-0"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center",staticStyle:{"white-space":"nowrap"}},[_c('div',{staticClass:"col-lg-auto"},[_c('form',{staticClass:"search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-input',{staticClass:"w-lg-auto",attrs:{"placeholder":"Search Questions"},on:{"input":_vm.onSearch},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('b-btn',{attrs:{"variant":"flush","type":"submit"}},[_c('md-icon',{domProps:{"textContent":_vm._s('search')}})],1)],1)]),_c('div',{staticClass:"col-lg d-flex flex-wrap justify-content-end"},[_c('div',{staticClass:"col-lg-6 mx-0 px-0"}),_c('b-btn',{staticClass:"ml-2",attrs:{"to":{
                  name:
                    _vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.EMPLOYER
                      ? 'emp-add-question'
                      : 'add-question',
                },"exact":"","variant":"primary"},domProps:{"textContent":_vm._s('Add Question')}})],1)])]),_c('b-table',{staticClass:"table-nowrap",attrs:{"fields":_vm.tableColumns,"items":_vm.allQuestions,"busy":_vm.isLoading,"head-variant":"light","hover":"","responsive":"","no-local-sorting":""},on:{"sort-changed":_vm.onSortChange,"row-clicked":_vm.editInstructor},scopedSlots:_vm._u([{key:"cell(question)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.title))]}},{key:"cell(type)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(("quesTypeOptions." + (data.item.type)))))]}},{key:"head(actions)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(actions)",fn:function(data){return [_c('router-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",modifiers:{"hover":true,"right":true}}],staticClass:"svg-icon mr-2",attrs:{"to":{
                name:
                  _vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.EMPLOYER
                    ? 'emp-edit-question'
                    : 'edit-question',
                params: { id: data.item.id },
              },"title":'Edit'}},[_c('i',{staticClass:"material-icons"},[_vm._v("edit")])]),_c('a',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",modifiers:{"hover":true,"right":true}}],attrs:{"href":"#","title":'Remove Question'},on:{"click":function($event){$event.preventDefault();return _vm.removeQuestion(data.item.id)}}},[_c('i',{staticClass:"material-icons text-danger"},[_vm._v("delete")])])]}}])}),_c('div',{staticClass:"card-footer"},[_c('pagination',{attrs:{"total-rows":_vm.totalQuestions,"per-page":_vm.perPage,"aria-controls":"schools-table"},on:{"change":_vm.onPageChange},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }