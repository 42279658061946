<template>
  <div :class="containerClass">
    <page-header
      title="Organization Profile"
      :container-class="null"
      class="mb-32pt"
      info-text="Manage your organization profile."
    />

    <page-separator title="Organization's Info" />

    <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
      <!-- Logo -->
      <b-form-group class="row-align-items-center">
        <label class="form-label">
          Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isLogoUploading">...</span>
            <b-img
              :src="organization.logoUrl"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-else-if="organization.logoUrl"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'logo')"
            :isUploading="isLogoUploading"
            :isRequired="!organization.logoUrl"
            placeholder="Select Organization's Logo Picture"
          />
        </b-media>
      </b-form-group>

      <!-- Small Logo -->
      <b-form-group label-for="small_logo_url" class="row-align-items-center">
        <label class="form-label">
          Small Logo
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="
              `Ideal dimensions for small logo should be 512*512. This will be shown as app's favicon.`
            "
            >info</i
          >
        </label>
        <b-media class="align-items-center" vertical-align="center">
          <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
            <span v-if="isSmallLogoUploading">...</span>
            <b-img
              :src="organization.small_logo_url"
              class="img-fluid"
              width="40"
              alt="Logo"
              v-else-if="organization.small_logo_url"
            />
            <i class="fas fa-university fa-lg" v-else></i>
          </fmv-avatar>

          <image-uploader
            @image="e => setImage(e, 'smallLogo')"
            :isUploading="isSmallLogoUploading"
            :isRequired="!organization.small_logo_url"
            placeholder="Select Organization's Small Logo Picture"
          />
        </b-media>
      </b-form-group>

      <!-- Name -->
      <b-form-group label="Name" label-for="name" label-class="form-label">
        <b-form-input id="name" placeholder="Enter Organization's Name" v-model="organization.name" required />
      </b-form-group>

      <b-form-group label="Contact Email" label-for="email" label-class="form-label">
        <b-form-input
          id="email"
          placeholder="Enter Organization's Contact Email"
          v-model="organization.email"
          type="email"
        />
      </b-form-group>

      <b-form-group label="Organization Type" label-for="organization-type" label-class="form-label">
        <v-select
          id="organization-type"
          class="form-control v-select-custom"
          label="text"
          v-model="organizationType"
          :reduce="item => item.value"
          placeholder="Select Organization Type"
          :options="OrganizationTypeOptions"
        >
        </v-select>
      </b-form-group>

      <!-- Phone -->
      <b-form-group :label="$t('schoolMsgs.phone')" label-for="phone" label-class="form-label">
        <b-form-input
          id="phone"
          placeholder="(888) 689 - 1235"
          v-model="organization.phone"
          v-mask="'(###) ### - ####'"
          required
        />
      </b-form-group>

      <!-- Address Fields -->
      <b-form-group :label="$t('schoolMsgs.address')" label-for="address" label-class="form-label">
        <b-form-input id="address" :placeholder="$t('schoolMsgs.address')" v-model="organization.address" required />
      </b-form-group>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.zipcode')" label-for="zipcode" label-class="form-label" class="col-md-6">
          <b-form-input id="zipcode" :placeholder="$t('schoolMsgs.zipcode')" v-model="organization.zipcode" required />
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.city')" label-for="city" label-class="form-label" class="col-md-6">
          <b-form-input id="city" :placeholder="$t('schoolMsgs.city')" v-model="organization.city" required />
        </b-form-group>
      </div>

      <div class="row">
        <b-form-group :label="$t('schoolMsgs.state')" label-for="state" label-class="form-label" class="col-md-6">
          <v-select
            id="state"
            class="form-control v-select-custom"
            label="text"
            v-model="organization.state"
            :reduce="state => state.value"
            placeholder="Select State"
            :options="stateOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!organization.state" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>

        <b-form-group :label="$t('schoolMsgs.country')" label-for="country" label-class="form-label" class="col-md-6">
          <v-select
            id="country"
            class="form-control v-select-custom"
            label="text"
            disabled
            v-model="organization.country"
            :reduce="country => country.value"
            :placeholder="$t('schoolMsgs.countryPlaceholder')"
            :options="countryOptions"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" :required="!organization.country" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
        </b-form-group>
      </div>

      <!-- Other Details -->

      <b-form-group label="Short Bio" label-for="short_bio" label-class="form-label">
        <b-form-textarea
          id="short_bio"
          placeholder="Enter Organization's Short/Summarized Bio."
          :rows="5"
          v-model="organization.short_bio"
        />
      </b-form-group>
      <b-form-group label="Bio" label-for="bio" label-class="form-label">
        <editor
          :init="{
            height: 400,
            menubar: 'edit view insert format tools table help',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount'
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
          }"
          v-model="organization.bio"
          placeholder="Enter Organization's Bio"
        />
      </b-form-group>
      <b-form-group label="Career Pathways" label-for="offeredCareers" label-class="form-label">
        <v-select
          id="offeredCareers"
          class="form-control v-select-custom"
          label="name"
          v-model="organization.offeredCareers"
          :reduce="item => item.id"
          placeholder="All Career Pathways By Default"
          :options="allCareers"
          :loading="areCareersLoading"
          multiple
        >
        </v-select>
      </b-form-group>
      <b-form-group
        :label="$t('schoolMsgs.profileBgImage')"
        label-for="profileBgImage"
        label-class="form-label"
        class="row-align-items-center"
      >
        <b-media class="align-items-center" vertical-align="center">
          <image-uploader
            @image="e => setImage(e, 'profileBgImage')"
            :isUploading="isProfileBgImgUploading"
            :isRequired="false"
            :placeholder="$t('schoolMsgs.profileBgImageDesc')"
          />
        </b-media>
      </b-form-group>

      <div class="row">
        <b-form-group
          :label="$t('schoolMsgs.primaryColor')"
          label-for="primaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="organization.primaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.secondaryColor')"
          label-for="secondaryColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="organization.secondaryColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
        <b-form-group
          :label="$t('schoolMsgs.darkColor')"
          label-for="darkColor"
          label-class="form-label"
          class="col-md-4"
        >
          <v-swatches
            v-model.trim="organization.darkColor"
            shapes="circles"
            show-fallback
            :swatches="swatches"
            fallback-input-type="color"
            :fallback-ok-text="$t('generalMsgs.select')"
            fallback-input-class="py-0"
          ></v-swatches>
        </b-form-group>
      </div>

      <page-separator title="Employer's Configuration" />

      <b-form-group>
        <b-form-checkbox id="checkbox-5" v-model="organization.allow_emp_deduct" name="checkbox-5" switch
          >Allow Employer Deduction?
          <i
            class="material-icons icon-16pt clickable-item text-muted"
            v-b-popover.hover.top="`Allow your employers to use your tokens and job credits.`"
            >info</i
          >
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <b-form-checkbox id="checkbox-6" v-model="organization.show_all_school" name="checkbox-6" switch
          >Allow All Transition Schools?
        </b-form-checkbox>
      </b-form-group>

      <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
        <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
        <span v-else>Update</span>
      </b-btn>
    </b-form>
  </div>
</template>

<script>
import axios from 'axios';
import hexRgb from 'hex-rgb';
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Editor from '@tinymce/tinymce-vue';
import { DEFAULT_FAVICON } from '../../../common/constants';
import Page from '../../../components/Page.vue';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';

export default {
  name: 'ManageOrgProfile',
  components: {
    PageHeader,
    PageSeparator,
    FmvAvatar,
    VSwatches,
    Editor,
    ImageUploader
  },
  extends: Page,

  data() {
    return {
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      swatches: [
        ['#1FBC9C', '#1CA085', '#2ECC70', '#3398DB'],
        ['#00b3e6', '#8E43AD', '#3D556E', '#222F3D'],
        ['#214e6f', '#F39C19', '#E84B3C', '#C0382B'],
        ['#DDE6E8', '#BDC3C8', '#C0382B', '#868e96']
      ],
      title: 'Organization Profile',

      organization: {
        name: '',
        email: '',
        phone: '',

        zipcode: '',
        address: '',
        city: '',
        state: '',
        allow_emp_deduct: false,
        show_all_school: true,
        country: 'United States',
        logoUrl: null,
        small_logo_url: null,

        primaryColor: '#00b3e6',
        secondaryColor: '#868e96',
        darkColor: '#214e6f',

        bio: '',
        short_bio: '',
        offeredCareers: []
      },
      is_published: false,
      wbst_domain: '',
      fieldTypes: { password: 'text', repeatPassword: 'text' },
      countryOptions: [],
      stateOptions: [],
      errorStates: {
        password: null,
        userEmail: null,
        phone: null
      },
      passErrorCode: null,

      isPassCopied: false,
      isLoading: false,
      isLogoUploading: false,
      isAvatarUploading: false,
      isSmallLogoUploading: false,
      isProfileBgImgUploading: false,
      uploadFile: {
        logo: null,
        avatar: null,
        smallLogo: null,
        profileBgImage: null
      },
      uploadPercent: { logo: 0, smallLogo: 0, avatar: 0, profileBgImage: 0 },
      uploadCancelTokenSource: {
        logo: null,
        avatar: null,
        smallLogo: null,
        profileBgImage: null
      },

      OrganizationTypeOptions: [
        { value: 'enterprise', text: 'Enterprise' },
        { value: 'association', text: 'Association' },
        { value: 'non_profit', text: 'Non Profit' },
        { value: 'affiliate', text: 'Affiliate' },
        { value: 'other', text: 'Other' }
      ],

      organizationType: '',
      otherOrganizationType: null,
      allCareers: [],
      areCareersLoading: false
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Organizations', to: { name: 'manage-organizations' } },
        {
          text: this.$route.params.id ? 'Edit Organization' : 'Add Organization',
          active: true
        }
      ];
    },
    isFormLoading() {
      return this.isLoading || this.isLogoUploading;
    }
  },
  validations() {
    return {};
  },
  methods: {
    ...mapActions('organization', ['getOrganization', 'updateMyOrgProfile', 'getOrgImageUploadPresignedUrl']),
    ...mapActions('navbarConfig', ['updateNavbarBrandDetails']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),

    async fetchCareers() {
      this.areCareersLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.allCareers = response.data;

      this.areCareersLoading = false;
    },

    get,
    setImage(file, image) {
      this.uploadFile[image] = file;
      this.uploadImage(image);
    },

    handlePassType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;

      if (type === 'blur' && !value) {
        this.fieldTypes[name] = 'text';
      } else {
        this.fieldTypes[name] = 'password';
      }
    },

    async onSubmit() {
      this.isLoading = true;

      try {
        const data = {
          name: this.organization.name,
          contact_email: this.organization.email,
          is_active: true,
          zipcode: this.organization.zipcode,
          address: this.organization.address,
          city: this.organization.city,
          state: this.organization.state,
          country: this.organization.country,
          allow_emp_deduct: this.organization.allow_emp_deduct,
          show_all_school: this.organization.show_all_school,
          logo_url: this.organization.logoUrl,
          small_logo_url: this.organization.small_logo_url,
          profile_bg_img_url: this.organization.profile_bg_img_url,
          primary_color: this.organization.primaryColor,
          secondary_color: this.organization.secondaryColor,
          dark_color: this.organization.darkColor,
          dark_color_rgb: hexRgb(this.organization.darkColor, { alpha: 0.5 }),

          bio: this.organization.bio,
          short_bio: this.organization.short_bio,

          type: this.organizationType,
          other_organization_type: this.otherOrganizationType,
          offered_careers: this.organization.offeredCareers
        };

        await this.updateMyOrgProfile({
          ...data
        });
        this.makeToast({ variant: 'success', msg: 'Organization Profile Updated' });
        document.documentElement.style.setProperty('--primary-color', this.organization.primaryColor);
        document.documentElement.style.setProperty('--secondary-color', this.organization.secondaryColor);
        document.documentElement.style.setProperty('--dark-color', this.organization.darkColor);
        const favicon = document.getElementById('favicon');

        favicon.href = get(this.organization, 'small_logo_url') || DEFAULT_FAVICON;
        this.updateNavbarBrandDetails();
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },
    async copyPass() {
      try {
        await navigator.clipboard.writeText(this.user.password);
        this.isPassCopied = true;
        setTimeout(() => (this.isPassCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';
      this.isSmallLogoUploading = uploadType === 'smallLogo';
      this.isAvatarUploading = uploadType === 'avatar';
      this.isProfileBgImgUploading = uploadType === 'profileBgImage';
      try {
        const urlResp = await this.getOrgImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'logo') {
          this.organization.logoUrl = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'smallLogo') {
          this.organization.small_logo_url = urlResp.upload_url.split('?')[0];
        } else if (uploadType === 'profileBgImage') {
          this.organization.profile_bg_img_url = urlResp.upload_url.split('?')[0];
        } else {
          this.user.avatarUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
      else if (uploadType === 'smallLogo') this.isSmallLogoUploading = false;
      else if (uploadType === 'profileBgImage') this.isProfileBgImgUploading = false;
      else this.isAvatarUploading = false;
    }
  },

  async mounted() {
    this.isLoading = true;
    this.fetchCareers();
    var usStates = new UsaStates();
    this.stateOptions = this.stateOptions.concat(
      map(usStates.states, state => ({
        value: state.name,
        text: state.name
      }))
    );
    this.countryOptions = this.countryOptions.concat(
      map(countries, country => ({
        value: country.name,
        text: country.name
      }))
    );
    try {
      const resp = (await this.getOrganization(this.getLoggedInUser.linked_entity.id)).data;
      this.organization.name = resp.name;
      this.organization.email = resp.contact_email;
      this.organization.phone = resp.phone;
      this.organization.zipcode = resp.zipcode;
      this.organization.address = resp.address;
      this.organization.city = resp.city;
      this.organization.state = resp.state;
      this.organization.country = resp.country;
      this.organization.logoUrl = resp.logo_url;
      this.organization.small_logo_url = resp.small_logo_url;
      this.organization.profile_bg_img_url = resp.profile_bg_img_url;
      this.organization.primaryColor = resp.primary_color;
      this.organization.secondaryColor = resp.secondary_color;
      this.organization.darkColor = resp.dark_color;

      this.organization.bio = resp.bio;
      this.organization.short_bio = resp.short_bio;
      this.organizationType = resp.type;
      this.organization.allow_emp_deduct = resp.allow_emp_deduct;
      this.organization.show_all_school = resp.show_all_school;
      this.organization.offeredCareers = get(resp, 'offered_careers');
    } catch (e) {
      this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
    }

    this.isLoading = false;
  }
};
</script>
