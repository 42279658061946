<template>
  <b-modal ref="copyUrlModal" hide-footer title="Invite Institutes" centered lazy scrollable @hide="hideModal">
    <div class="p-2 mb-4">Use the invite link below to invite institutes on transition.</div>
    <b-alert variant="soft-light px-12pt" show class="col-md-12">
      <div class="d-flex align-items-center justify-content-between">
        <span class="text-black-100"
          ><strong>{{ inviteUrl }}</strong></span
        >

        <span v-if="isCopied" class="text-success"><md-icon>check</md-icon> Copied</span>
        <a href="#" @click.prevent="copyDomain" v-else v-b-popover.hover.top="'Copy Invite Link'">
          <md-icon>content_copy</md-icon>
        </a>
      </div>
    </b-alert>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'CopyUrlModal',

  components: {},

  props: {
    showModal: { type: Boolean, default: false },
  },

  data() {
    return {
      modalTitle: '',
      states: [],
      isLoading: false,
      isCopied: false,
      inviteUrl: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.inviteUrl = this.getClickableLink(
          `${process.env.VUE_APP_MAIN_DOMAIN}/school-interest/?organization=${get(
            this.getLoggedInUser,
            'linked_entity.slug'
          )}`
        );
        this.$refs.copyUrlModal.show();
      }
    },
  },

  methods: {
    get,
    async copyDomain() {
      try {
        await navigator.clipboard.writeText(this.inviteUrl);
        this.isCopied = true;
        setTimeout(() => (this.isCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://')
        ? link
        : `${process.env.VUE_APP_DOMAIN_PREFIX || 'https'}://${link}`;
    },
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.copyUrlModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
