<template>
  <div>
    <div :class="containerClass">
      <page-header
        :title="title"
        :container-class="containerClass"
        :info-text="
          getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
            ? `List of spotlights posted by enrolled / aspiring student of a school so that you can sponsor.`
            : getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
            ? 'List of spotlights posted by students seeking for your assistance.'
            : 'Review student spotlights for scholarship applications.'
        "
      />

      <div class="page-section candidates_container">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-24pt" style="white-space: nowrap">
          <div class="w-auto ml-sm-auto table d-flex align-items-center mb-sm-0">
            <!-- <small class="text-muted text-headings text-uppercase mr-1 d-sm-block" v-if="!isMobSmallScreen"
            >Sort by</small
          >
          <b-dropdown :disabled="getIsFilterLoading" variant="link text-black-70" right no-caret>
            <template #button-content>
              <span>{{ $t(`studentEmpSortOptions.${ordering}`) }}<i class="fas fa-sort ml-1"></i></span>
            </template>

            <b-dropdown-item :active="ordering === 'name'" @click="onSortChange({ sortBy: 'name' })"
              >Name</b-dropdown-item
            >
            <b-dropdown-item :active="ordering === 'city'" @click="onSortChange({ sortBy: 'city' })"
              >Location</b-dropdown-item
            >
            <b-dropdown-item
              :active="ordering === 'active_students'"
              @click="onSortChange({ sortBy: 'active_students' })"
              >Active Students</b-dropdown-item
            >
          </b-dropdown> -->

            <div
              v-if="
                !(
                  get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL ||
                  get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SUPER_ADMIN
                )
              "
            >
              <b-btn v-if="enableSidebarToggle !== false" v-fmv-toggle.filters size="sm" variant="white" class="mr-2">
                <md-icon left>tune</md-icon>
                <span>Filters</span>
              </b-btn>
            </div>
            <!-- <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
            <b-form-input class="w-lg-auto" placeholder="Search..." v-model="searchTerm" @input="onSearch" />
            <b-btn variant="flush" type="submit">
              <md-icon v-text="'search'" />
            </b-btn>
          </form> -->
          </div>
        </div>

        <page-separator
          :title="
            !isLoading
              ? `showing ${
                  totalStudents ? `${pageFromCount}-${pageToCount}` : 0
                } of ${totalStudents} Sponsor Spotlights`
              : ''
          "
        />

        <div class="page-section d-flex justify-content-center" v-if="!students.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Sponsored Spotlights</h4>

              <p class="text-muted">Couldn't find any Sponsored Spotlights.</p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row card-group-row mb-lg-12pt home-card">
              <div class="col-md-6 col-lg-6 card-group-row__col" v-for="item in [1, 2]" :key="item">
                <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                  <div class="card-header d-flex align-items-center">
                    <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                  </div>
                  <div class="card-body d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <div class="flex">
                        <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                          <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                            <a
                              href="#"
                              @click.prevent
                              class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                              style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                            >
                              <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                            </a>
                          </div>
                          <div class="flex">
                            <div class="mb-0" style="text-transform: none">
                              <b-skeleton width="100%"></b-skeleton>
                              <b-skeleton width="55%"></b-skeleton>
                              <b-skeleton width="70%"></b-skeleton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div v-for="std in students" :key="std.id" class="col-lg-6 col-md-6 col-sm-12 clickable-item mb-3">
              <std-card :std="std" />
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalStudents > students.length"
          v-model="currentPage"
          :total-rows="totalStudents"
          :per-page="perPage"
          @change="onPageChange"
          aria-controls="students-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/Ui/PageHeader.vue';
import { debounce, get } from 'lodash';
import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import Filters from './Filters/Filters.vue';
import FiltersSidebar from './Filters/FiltersSidebar.vue';

import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLE_TYPES } from '@/common/constants';
import { formatTimeAgo } from '@/common/utils';
import StdCard from './StdCard.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  components: { Pagination, StdCard, PageHeader, PageSeparator },
  extends: Page,

  data() {
    return {
      DefaultAvatar,
      title: 'Spotlights',
      isLoading: false,
      perPage: 14,
      currentPage: 1,
      totalStudents: 0,
      students: [],
      pageFromCount: 0,
      pageToCount: 0,
      filters: {
        programs__title: null,
        school__name: null,
      },
      searchTerm: '',

      windowWidth: window.innerWidth,
      firstLoad: true,
      USER_ROLE_TYPES,
    };
  },

  watch: {
    getStoriesEmpFilters: {
      deep: true,
      handler(filterValues) {
        this.filters.programs__title = filterValues.programs__title;

        this.filters.school__name = filterValues.school__name;

        this.debouncedSearchStudents(this);
      },
    },
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('stories', ['getStoriesEmpFilters']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Candidates', active: true },
      ];
    },

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    subLayout() {
      if (
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL ||
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SUPER_ADMIN
      ) {
        return null;
      } else {
        return this.windowWidth > 992;
      }
    },
    subLayoutDrawer() {
      if (
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL ||
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SUPER_ADMIN
      ) {
        return null;
      } else {
        return this.windowWidth > 992 ? FiltersSidebar : Filters;
      }
    },
    subLayoutDrawerId() {
      if (
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL ||
        get(this.getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SUPER_ADMIN
      ) {
        return null;
      } else {
        return 'filters';
      }
    },

    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },
  },

  methods: {
    ...mapActions('stories', ['getAllStdStories']),
    get,

    formatTimeAgo,

    async fetchStudents(pageNum = 1, params = {}) {
      this.isLoading = true;
      try {
        let response = await this.getAllStdStories({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...(this.searchTerm && { search: this.searchTerm }),
          ...this.filters,
          ...params,
        });

        this.students = response.data.results;
        this.currentPage = pageNum;
        this.totalStudents = response.data.count;

        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
        this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchStudents(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchStudents();
    },

    onSearch() {
      this.debouncedSearchStudents(this);
    },

    debouncedSearchStudents: debounce((vm) => {
      vm.fetchStudents();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchStudents();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
